import {
  DeleteOutlined,
  UndoOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Row, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import { colors } from "../../../style/variables";
import AuthorizationContext from "../../contexts/AuthorizationContext/AuthorizationContext";
import "./ActionDetail.scss";
import useAuthorization from "../../../hooks/useAuthorization";
import claims from "../../../resources/auth/claims";
import { deleteAction } from "../../../queries/deleteAction";
import { AuditLogDeleteBaseDto } from "../../../dtos/AuditLogDeleteBaseDto";
import { CreateAuditLogDelete } from "../../../queries/createAuditLogDelete";
interface ActionDeleteProps {
  currentActionId?: number;
}
const cardStyle = {
  borderStyle: "solid",
  borderWidth: "3px",
  borderColor: colors.erggreen,
  minHeight: "150px",
  overflow: "scroll",
  maxHeight: "150px",
};
const headStyle = {
  borderStyle: "solid",
  borderWidth: "3px",
  borderColor: colors.erggreen,
  background: colors.erggreen,
  color: "white",
  fontSize: "16px",
  width: "80%",
};
interface ActionDeleteProps {
  currentActionId?: number;
  setCurrentActionId: (actionId: number | undefined) => void;
  handleCancel: () => void;
  handleOk: () => void;
}
function ActionDelete(props: ActionDeleteProps): JSX.Element {
  const authContext = useContext(AuthorizationContext);
  const currUser = authContext?.user;
  const { t, i18n } = useTranslation();
  const [currentActionId, setCurrentActionId] = useState(props.currentActionId);
  const [apiPathList, setApiPathList] = useState(apiPaths.auditActions);
  const [isUserAuthorized, isAuthenticated, user] = useAuthorization();

  const isAdmin = isUserAuthorized([claims.groups.admin]);

   //On currentactionid change
   useEffect(() => {
    setCurrentActionId(props.currentActionId);
   }, [props.currentActionId]);

   const onDeleteAction = async () => {
    await deleteAction(currentActionId!);

    //Log in the ActionLogDelete table
    const auditLog: AuditLogDeleteBaseDto = {
      auditActionId: props.currentActionId,
      notes: currUser?.username,
    };
    CreateAuditLogDelete(auditLog);
    props.handleOk();
  };

  return (
    <div style={{ marginLeft: "30px", marginRight: "30px" }}>
      {currentActionId && isAdmin && (
        <>
          <Row style={{ marginBottom: "10px" }} gutter={18}>
            <Col>{t(keys.CONFIRM_DELETE_MSG)}</Col>
          </Row>
          {isAdmin && (
              <>
                <Space size="large" direction="horizontal">
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={onDeleteAction}
                    type="primary"
                    htmlType="submit"
                  >
                    {t(keys.DELETE)}
                  </Button>
                  <Button
                    icon={<UndoOutlined />}
                    onClick={props.handleCancel}
                    htmlType="button"
                  >
                    {t(keys.UNDO)}
                  </Button>
                </Space>
              </>
            )}
        </>
      )}
    </div>
  );
}

export default ActionDelete;
