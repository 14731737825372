import { Navigate, Outlet, Route } from "react-router";
import useAuthorization from "../../../hooks/useAuthorization";
import claims from "../../../resources/auth/claims";
import routes from "../../../resources/navigation/routes";
import AccessLogPage from "../../pages/AccessLogPage/AccessLogPage";
import ActionListPage from "../../pages/ActionListPage/ActionListPage";
import AlertMessagePage from "../../pages/AlertMessagesPage/AlertMessagePage";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import HomePage from "../../pages/HomePage/HomePage";
import TestPage from "../../pages/Test/TestPage";
import ErgLayout from "../../ui/layout/ErgLayout/ErgLayout";
import LayoutRoutes from "../LayoutRoutes/LayoutRoutes";
import DeleteLogPage from "../../pages/DeleteLogPage/DeleteLogPage";
const ProtectedRoute = (props: { isValid: boolean }) => {
  return props.isValid ? <Outlet /> : <Navigate to="/login" />;
};
function Routes() {
  const [isUserAuthorized, isAuthenticated, user] = useAuthorization();

  return (
    <LayoutRoutes
      layoutComponent={ErgLayout}
      excludedPaths={[routes.error.path, routes.fallback.path]}
    >
      <Route path={routes.index.path} element={<HomePage />} />
      <Route path={routes.test.path} element={<TestPage />} />
      <Route path={routes.error.path} element={<ErrorPage />} />

      <Route path={routes.home.path} element={<HomePage />} />

      <Route path={routes.actionList.path} element={<ActionListPage />} />
      <Route
        path={routes.alertMessages.path}
        element={
          <ProtectedRoute isValid={isUserAuthorized([claims.groups.admin])} />
        }
      >
        <Route
          path={routes.alertMessages.path}
          element={<AlertMessagePage />}
        />
      </Route>
      <Route
        path={routes.accesslog.path}
        element={
          <ProtectedRoute isValid={isUserAuthorized([claims.groups.admin])} />
        }
      >
        <Route path={routes.accesslog.path} element={<AccessLogPage />} />
      </Route>
      <Route
        path={routes.deletelog.path}
        element={
          <ProtectedRoute isValid={isUserAuthorized([claims.groups.admin])} />
        }
      >
        <Route path={routes.deletelog.path} element={<DeleteLogPage />} />
      </Route>
      <Route
        path={routes.fallback.path}
        element={<Navigate to={routes.httpNotFoundError.path} />}
      />
    </LayoutRoutes>
  );
}

export default Routes;
