import { message } from "antd";
import Layout, { Content } from "antd/es/layout/layout";
import { useState } from "react";
import IBaseComponentProps from "../../../../interfaces/IBaseComponentProps";
import LayoutContext, {
  ILayoutContext,
} from "../../../contexts/LayoutContext/LayoutContext";
import ErgHeader from "../ErgHeader/ErgHeader";
import ErgSider from "../ErgSider/ErgSider";
import MessageContent from "../MessageContent/MessageContent";
import "./ErgLayout.scss";

interface IErgLayoutProps extends IBaseComponentProps {}

function ErgLayout(props: IErgLayoutProps): JSX.Element {
  const [messageApi, contextHolder] = message.useMessage();
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(true);
  const layoutContext: ILayoutContext = {
    navMenu: {
      isCollapsed: isSiderCollapsed,
      setIsCollapsed: setIsSiderCollapsed,
    },
    messageNotifier: {
      error: (message?: string) => {
        messageApi.open({
          content: (
            <MessageContent
              message={message ?? "default error"}
              type={"error"}
            />
          ),
        });
      },
      warning: (message?: string) => {
        messageApi.open({
          content: (
            <MessageContent
              message={message ?? "default error"}
              type={"error"}
            />
          ),
        });
      },
      success: (message?: string) => {
        messageApi.open({
          content: (
            <MessageContent
              message={message ?? "default error"}
              type={"success"}
            />
          ),
        });
      },
    },
  };

  return (
    <Layout className="erg-layout erg-h-100">
      <LayoutContext.Provider value={layoutContext}>
        <ErgHeader />
        <Layout>
          <ErgSider className="erg-layout-sider" />
          <Layout>
            <Content className="erg-main erg-h-100 erg-scroll-y">
              {contextHolder}
              {props.children}
            </Content>
          </Layout>
        </Layout>
      </LayoutContext.Provider>
    </Layout>
  );
}

export default ErgLayout;
