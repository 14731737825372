interface ClaimsResourceTypes {
  ergClaimType: string;
  groups: string;
  roles: string;
  name: string;
  surname: string;
  email: string;
  userId: string;
  dbUserId: string;
  roleName: string;
  landing: string;
}

interface ClaimsResourcePages {
  test: string;
  users: string;
}

interface ClaimsResourceGroups {
  user: string;
  admin: string;
}

interface ClaimsResourceEntityApis {
  user: {
    read: string;
    update: string;
    create: string;
    delete: string;
  };
}

const claims: {
  types: ClaimsResourceTypes;
  apis: ClaimsResourceEntityApis;
  pages: ClaimsResourcePages;
  groups: ClaimsResourceGroups;
} = {
  types: {
    ergClaimType: "Erg.Api",
    email: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    groups: "http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid",
    roles: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    name: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname",
    surname: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
    userId: "http://schemas.microsoft.com/identity/claims/objectidentifier",
    dbUserId: "dbuser.id",
    roleName: "role.name",
    landing: "landing",
  },
  apis: {
    user: {
      read: "Erg.Api.User.Read",
      update: "Erg.Api.User.Update",
      create: "Erg.Api.User.Create",
      delete: "Erg.Api.User.Delete",
    },
  },
  pages: {
    test: "Erg.Pages.Test",
    users: "Erg.Pages.Users",
  },
  groups: {
    user: "e2ef1df0-d883-42e9-8b6a-d79c1e8fabf7",
    admin: "e2ef1df0-d883-42e9-8b6a-d79c1e8fabf7",    //Dev
    //admin: "ebcbe069-3557-4885-aa1b-ddc1b73c3f39",  //Test/Prod
  },
};

export default claims;
