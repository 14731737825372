import { getDataAsync } from "../functions/apiMethods";
import { getQueryParamFromObject } from "../functions/utils";
import IApiPath from "../interfaces/IApiPath";
import IErgDataTableColumnSorter from "../interfaces/IErgDataTableColumnSorter";
import { IErgDataTableResponse } from "../interfaces/IErgDataTableResponse";

export async function GetErgTableDataAsync<TData>(
  apiPath: IApiPath,
  currentPage?: number,
  pageSize?: number,
  sorter?: IErgDataTableColumnSorter,
): Promise<IErgDataTableResponse<TData>> {
  var params = apiPath.params;
  if (!!currentPage && !!pageSize && !!params) {
    const paramsToAdd = getQueryParamFromObject({
      currentPage: currentPage,
      pageSize: pageSize,
    });
    params = params.concat(paramsToAdd);
  }
  var result: IErgDataTableResponse<TData> = await getDataAsync<
    IErgDataTableResponse<TData>
  >({
    apiPath: apiPath.path,
    queryparams: params,
  });
  return result;
}
