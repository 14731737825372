import {
  AccountInfo,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import configs from "../appSettings.json";
import ErrorDto from "../dtos/ErrorDto";
import apiUtils from "../resources/api/apiUtils";
import { msalConfig } from "../resources/auth/authConfig";
import httpUtils from "../resources/utils/httpUtils";
import { HttpMethod, InterpolatedString } from "../types";

interface IFetchDataProps<TBodyData> {
  apiMethod: HttpMethod;
  apiPath: string;
  queryparams?: string[];
  headerParams?: {};
  body?: TBodyData;
}

type IGetDataProps = Omit<IFetchDataProps<null>, "body" | "apiMethod">;
type IDeleteDataProps = IGetDataProps;
type IPostDataProps<TData> = Omit<IFetchDataProps<TData>, "apiMethod">;
type IPutDataProps<TData> = IPostDataProps<TData>;

//----AXIOS-----//
// gesione temporanea con axios perch� con fetch non sono stato in grado di scaricare correttamente il buffer array
// async function getFileRequestConfig(): Promise<AxiosRequestConfig> {
//   var token = await getTokenAsync();
//   return {
//       headers: {
//           "Authorization": `Bearer ${token}`,
//           'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//       },
//       responseType: "arraybuffer",
//   };
// }

// export async function axiosGetExcel(url: string) {
//   const config = await getFileRequestConfig();
//   const uri = `${configs.baseApiUri}${url}`;
//   return axios.get(uri, config);
// }

//----AXIOS-----//
async function getUrlFromAPIPath<TSentData>(
  props: IFetchDataProps<TSentData>,
): Promise<string> {
  //SET URL
  const uriPath = props.apiPath;
  uriPath.replace(/^\|+|\|+$/g, "");
  let arrayParams = [...(props.queryparams ?? [])];
  let parameters = arrayParams.join("&");
  const url = `${configs.baseApiUri}${uriPath}${
    parameters?.length !== 0 ?? false ? "?" : ""
  }${parameters}`;
  return url;
}
async function fetchDataAsync<TSentData, TReceivedData>(
  props: IFetchDataProps<TSentData>,
  downloadFile?: boolean,
  fileName?: string,
): Promise<TReceivedData> {
  const url = await getUrlFromAPIPath(props);

  var token = await getTokenAsync();

  //SET TIMEOUT
  const controller = new AbortController();
  const timeoutTimerId = setTimeout(
    () => controller.abort(),
    apiUtils.timeoutMs,
  );

  // SET HEADERS
  let headers = {};
  if (httpUtils.bodiedHttpMethods.includes(props.apiMethod))
    headers = { ...headers, "Content-Type": "application/json" };
  if (!!token) {
    let bearerValue = httpUtils.headers.values.bearer as InterpolatedString;
    headers = { ...headers, Authorization: bearerValue(token) };
  }
  if (!!props.headerParams) headers = { ...headers, ...props.headerParams };

  // SET BODY
  let body: BodyInit | undefined;
  if (
    httpUtils.bodiedHttpMethods.includes(props.apiMethod) &&
    props.body !== null &&
    props.body !== undefined
  )
    body = props.body as BodyInit;

  // FETCH
  var response = fetch(url, {
    method: props.apiMethod,
    body: JSON.stringify(body),
    signal: controller.signal,
    headers: headers,
  });

  var httpResponse = await response;
  clearTimeout(timeoutTimerId);

  if (!httpResponse.ok) {
    let errorMessage: string;
    try {
      let error = (await httpResponse.json()) as ErrorDto;
      errorMessage = error.messageKey;
    } catch {
      // if response is not a valid json (or rempty) just return
      errorMessage = `ERROR_HTTP_${httpResponse.status}`;
    }

    throw new Error(errorMessage, {
      cause: "http_fetch",
    });
  }
  if (downloadFile) {
    //If we want to download we need to create a fake anchor and click it
    try {
      const data = await httpResponse.blob();
      const url = window.URL.createObjectURL(data);
      var a = document.createElement("a");
      a.href = url;
      a.download = fileName ?? "";
      a.click();
      window.URL.revokeObjectURL(url);
      return {} as TReceivedData;
    } catch (error) {
      // if response is not a valid json (or rempty) just return
      return {} as TReceivedData;
    }
  }
  try {
    let data = await httpResponse.json();
    return data as TReceivedData;
  } catch (error) {
    // if response is not a valid json (or rempty) just return
    return {} as TReceivedData;
  }
}
export async function getCurrUser(): Promise<AccountInfo> {
  const msalClient = new PublicClientApplication(msalConfig);
  await msalClient.initialize();
  const account = msalClient.getAllAccounts()[0];
  return account;
}
export async function getTokenAsync(): Promise<string> {
  const msalClient = new PublicClientApplication(msalConfig);
  await msalClient.initialize();
  const account = msalClient.getAllAccounts()[0];

  const accessTokenRequest: SilentRequest = {
    account: account,
    scopes: [...configs.azureAd.scopes],
  };

  const tokenError = new Error("failed token retrieval", {
    cause: "token",
  });

  let result = await msalClient
    .acquireTokenSilent(accessTokenRequest)
    .catch(function (error) {
      if (error! instanceof InteractionRequiredAuthError) throw tokenError;
    });

  if (!result || !result.accessToken)
    result = await msalClient
      .acquireTokenPopup(accessTokenRequest)
      .catch(function (error) {
        throw tokenError;
      });

  if (!result || !result.accessToken) throw tokenError;

  return result.accessToken;
}

export function getDataAsync<TData>(
  props: IGetDataProps,
  download?: boolean,
  filename?: string,
): Promise<TData> {
  const fetchProps: IFetchDataProps<null> = {
    apiMethod: "GET",
    apiPath: props.apiPath,
    queryparams: props.queryparams,
    headerParams: props.headerParams,
  };

  return fetchDataAsync(fetchProps, download, filename);
}

export function postDataAsync<TSentData, TReceivedData = void>(
  props: IPostDataProps<TSentData>,
): Promise<TReceivedData> {
  const fetchProps: IFetchDataProps<TSentData> = {
    apiMethod: "POST",
    apiPath: props.apiPath,
    body: props.body,
    queryparams: props.queryparams,
    headerParams: props.headerParams,
  };

  return fetchDataAsync(fetchProps);
}

export function putDataAsync<TSentData, TReceivedData = void>(
  props: IPutDataProps<TSentData>,
): Promise<TReceivedData> {
  const fetchProps: IFetchDataProps<TSentData> = {
    apiMethod: "PUT",
    apiPath: props.apiPath,
    body: props.body,
    queryparams: props.queryparams,
    headerParams: props.headerParams,
  };

  return fetchDataAsync(fetchProps);
}

export function patchDataAsync<TSentData, TReceivedData = void>(
  props: IPutDataProps<TSentData>,
): Promise<TReceivedData> {
  const fetchProps: IFetchDataProps<TSentData> = {
    apiMethod: "PATCH",
    apiPath: props.apiPath,
    body: props.body,
    queryparams: props.queryparams,
    headerParams: props.headerParams,
  };

  return fetchDataAsync(fetchProps);
}
export function deleteDataAsync(props: IDeleteDataProps): Promise<void> {
  const fetchProps: IFetchDataProps<null> = {
    apiMethod: "DELETE",
    apiPath: props.apiPath,
    queryparams: props.queryparams,
    headerParams: props.headerParams,
  };

  return fetchDataAsync(fetchProps);
}
