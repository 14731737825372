import { getDataAsync } from "../functions/apiMethods";
import IApiPath from "../interfaces/IApiPath";
import { IErgDataTableResponse } from "../interfaces/IErgDataTableResponse";

export async function DownloadFileQuery<TData>(
  apiPath: IApiPath,
  language: string,
) {
  var params = apiPath.params?.concat(`&language=${language}`);

  await getDataAsync<IErgDataTableResponse<TData>>(
    {
      apiPath: apiPath.path,
      queryparams: params,
    },
    true,
    apiPath.downloadFileName,
  );
}
