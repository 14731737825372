import {
  // DeleteFilled,
  // DeleteOutlined,
  // EditOutlined,
  ClearOutlined,
  SearchOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { BusinessUnitLeaderDto } from "../../../dtos/BusinessUnitLeaderDto";
import { ActionStatus } from "../../../enumerations/ActionStatus";
import { ObservationType } from "../../../enumerations/ObservationType";
import { onlyUnique } from "../../../functions/utils";
import useAuthorization from "../../../hooks/useAuthorization";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import claims from "../../../resources/auth/claims";
import { keys } from "../../../resources/translations/keys";
import { colors } from "../../../style/variables";
import dayjs from 'dayjs';

const { Panel } = Collapse;
const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const tailLayout = {
  wrapperCol: { span: 16 },
  style: { marginLeft: "20px" },
};

interface AuditActionTableFiltersProps {
  onFinish: (values: any) => void;
  onViewExpired: (values: any) => void;
  downloadButton: JSX.Element;
}
const { RangePicker } = DatePicker;

function AuditActionTableFilters(
  props: AuditActionTableFiltersProps,
): JSX.Element {
  const { t } = useTranslation();
  const [businessUnitLeaders, setBusinessUnitLeaders] =
    useState<BusinessUnitLeaderDto[]>();
  const formRef = React.useRef<FormInstance>(null);
  const [isUserAuthorized, isAuthenticated, user] = useAuthorization();

  const isAdmin = isUserAuthorized([claims.groups.admin]);

  const onSearch = () => {
    formRef.current?.submit();
  };
  const onViewExpired = () => {
    formRef.current?.setFieldValue("expirationDate", null);
    formRef.current?.setFieldValue("status", ActionStatus.open);
    props.onViewExpired(formRef.current?.getFieldsValue());
  };
  const onReset = () => {
    formRef.current?.resetFields();
  };
  const query = useQuery({
    queryKey: [
      isAdmin ? apiPaths.getBusinessUnitsAdmin : apiPaths.getBusinessUnits,
    ],
    queryFn: () =>
      GetErgTableDataAsync(
        isAdmin ? apiPaths.getBusinessUnitsAdmin : apiPaths.getBusinessUnits,
      ),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: async (data: IErgDataTableResponse<BusinessUnitLeaderDto>) =>
      await parseAndSetData(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const parseAndSetData = async (
    data: IErgDataTableResponse<BusinessUnitLeaderDto>,
  ) => {
    setBusinessUnitLeaders(data.items);
  };

  const uniqueBULeaders = Array.from(
    new Map(businessUnitLeaders?.map((b) => [b["principalName"], b.fullName])),
  );
  const businessUnitLeadersOptions = uniqueBULeaders.map((b) => {
    return <Select.Option value={b?.[0]}>{b?.[1]}</Select.Option>;
  });

  const businessUnitsOptions = businessUnitLeaders
    ?.map((b) => {
      return b.businessUnit?.description;
    })
    .filter(onlyUnique)
    .map((b) => {
      return <Select.Option value={b}>{b}</Select.Option>;
    });
  return (
    <>
      <div style={{ marginTop: "2vw" }}>
        <Form
          {...layout}
          ref={formRef}
          name="control-ref"
          onFinish={props.onFinish}
          initialValues={{ status: [ActionStatus.open] }}
          size="middle"
          style={{ marginTop: "10" }}
        >
          <Collapse
            style={{
              marginBottom: "20px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
            onChange={() => {}}
          >
            <Panel
              style={{
                width: "100%",
                background: colors.erggreen,
                color: "white",
              }}
              header={<span style={{ color: "white" }}>{t(keys.FILTERS)}</span>}
              key={"1"}
            >
              <Row>
                <Col style={{ width: "100%" }} span={12}>
                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="accessDbId"
                    label={t(keys.ACTION_REFERENCE)}
                  >
                    <Input type="number" style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="expirationDate"
                    label={t(keys.ACTION_EXPIRY_DATE_RANGE)}
                  >
                    <RangePicker
                      allowEmpty={[true, true]}
                      style={{ width: "100%" }}
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>

                  <Form.Item
                    name="hasBeenDelegated"
                    label={t(keys.DELEGATED_ACTIONS)}
                  >
                    <Select allowClear>
                      <Select.Option value={true}>{t(keys.YES)}</Select.Option>
                      <Select.Option value={false}>{t(keys.NO)}</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="hasReceivedAsDelegate"
                    label={t(keys.RECEIVED_AS_DELEGATE_ACTIONS)}
                  >
                    <Select allowClear>
                      <Select.Option value={true}>{t(keys.YES)}</Select.Option>
                      <Select.Option value={false}>{t(keys.NO)}</Select.Option>
                    </Select>
                  </Form.Item>
                  <>
                    <Form.Item
                      style={{ textAlign: "center" }}
                      name="observationType"
                      label={t(keys.OBSERVATIONTYPE)}
                    >
                      <Select mode="multiple" allowClear>
                        <Select.Option value={ObservationType.Criticity}>
                          {t(keys.CRITICITY)}
                        </Select.Option>
                        <Select.Option value={ObservationType.ImprovementArea}>
                          {t(keys.IMPROVEMENTAREA)}
                        </Select.Option>

                        <Select.Option value={ObservationType.Attention}>
                          {t(keys.ATTENTION_THEME)}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </>
                </Col>
                <Col span={12}>
                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="owner"
                    label={t(keys.OWNER)}
                  >
                    <Select mode="multiple" allowClear>
                      {businessUnitLeadersOptions}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="businessUnit"
                    label={t(keys.BUSINESS_UNIT)}
                  >
                    <Select mode="multiple" allowClear>
                      {businessUnitsOptions}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="status"
                    label={t(keys.STATUS)}
                  >
                    <Select allowClear>
                      <Select.Option value={ActionStatus.open}>
                        {t(keys.STATUS_OPEN)}
                      </Select.Option>
                      <Select.Option value={ActionStatus.closed}>
                        {t(keys.STATUS_CLOSED)}
                      </Select.Option>

                      <Select.Option value={ActionStatus.NA}>
                        {t(keys.STATUS_NA)}
                      </Select.Option>

                      <Select.Option value={ActionStatus.notImplemented}>
                        {t(keys.STATUS_NOTIMPLEMENTED)}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="criticalityDegree"
                    label={t(keys.RISK)}
                  >
                    <Select mode="multiple" allowClear>
                      <Select.Option value={keys.GREEN}>
                        {t(keys.GREEN)}
                      </Select.Option>
                      <Select.Option value={keys.YELLOW}>
                        {t(keys.YELLOW)}
                      </Select.Option>
                      <Select.Option value={keys.RED}>
                        {t(keys.RED)}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Form.Item {...tailLayout}>
            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                htmlType="button"
                onClick={onSearch}
              >
                {t(keys.SEARCH)}
              </Button>
              <Button
                icon={<ClearOutlined />}
                htmlType="button"
                onClick={onReset}
              >
                {t(keys.RESET)}
              </Button>
              <Button
                icon={<ExclamationCircleOutlined />}
                type="primary"
                onClick={onViewExpired}
              >
                {t(keys.VIEW_EXPIRED_ACTIONS)}
              </Button>
              {props.downloadButton}
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default AuditActionTableFilters;
