import React, { useEffect, useState } from "react";
import { Routes, RoutesProps, useLocation } from "react-router-dom";
import ILayoutProps from "../../../interfaces/ILayoutProps";
import mappedRoutes, { RouteKey } from "../../../resources/navigation/routes";

interface ILayoutRoutesProps extends RoutesProps {
  layoutComponent: React.FunctionComponent<ILayoutProps>;
  excludedPaths?: string[] | null;
}

function LayoutRoutes(props: ILayoutRoutesProps) {
  const LayoutComponent = props.layoutComponent;
  const [hasLayout, setHasLayout] = useState<boolean>(false);
  const routes = <Routes>{props.children}</Routes>;
  const location = useLocation();

  useEffect(() => {
    if (!props.excludedPaths) {
      setHasLayout(true);
      return;
    }

    let matchedPath: string | undefined;
    Object.keys(mappedRoutes).forEach((key) => {
      var mappedRoutePath = mappedRoutes[key as RouteKey].path;
      if (!!matchedPath) return;
      if (mappedRoutePath === location.pathname) matchedPath = mappedRoutePath;
    });

    setHasLayout(
      !props.excludedPaths.includes(matchedPath ?? mappedRoutes.fallback.path),
    );
  }, [props.excludedPaths, location.pathname]);

  return hasLayout ? (
    <LayoutComponent>{routes}</LayoutComponent>
  ) : (
    <div className="erg-h-100">{routes}</div>
  );
}

export default LayoutRoutes;
