import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { Button, Modal, notification, Statistic, Tag } from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDelegateDto } from "../../../dtos/ActionDelegateDto";
import { AuditActionDto } from "../../../dtos/AuditActionDto";
import { AuditActionFiltersDto } from "../../../dtos/AuditActionFiltersDto";
import { ActionStatus } from "../../../enumerations/ActionStatus";
import {
  FormatDateString,
  getQueryParamFromObject,
  isActionAssignedToUser,
  isActionDelegatedToOthers,
} from "../../../functions/utils";
import useAuthorization from "../../../hooks/useAuthorization";
import IErgColumnType from "../../../interfaces/IErgDataTableColumnType";
import { DownloadFileQuery } from "../../../queries/downloadFileQuery";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import claims from "../../../resources/auth/claims";
import { keys } from "../../../resources/translations/keys";
import AuthorizationContext from "../../contexts/AuthorizationContext/AuthorizationContext";
import ErgDataTable from "../../data/ErgDataTable/ErgDataTable";
import ActionCreation from "../ActionDetail/ActionCreation";
import ActionDetail from "../ActionDetail/ActionDetail";
import "./AuditActionTable.scss";
import AuditActionTableFilters from "./AuditActionTableFilters";
import { colors } from "../../../style/variables";
import ActionDelete from "../ActionDetail/ActionDelete";
import dayjs from "dayjs";


function AuditActionTable(): JSX.Element {
  const authContext = useContext(AuthorizationContext);
  const currUser = authContext?.user;
  const { t, i18n } = useTranslation();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [notificationapi, contextHolder] = notification.useNotification();
  const [isUserAuthorized, isAuthenticated, user] = useAuthorization();

  const isAdmin = isUserAuthorized([claims.groups.admin]);
  const [selectedActionId, setSelectedActionId] = useState<number>();
  const [modalWidth, setModalWidth] = useState(90);
  const [count, setCount] = useState(0);
  const defaultFilters: AuditActionFiltersDto = {
    status: [ActionStatus.open],
  };
  const [filters, setFilters] = useState<AuditActionFiltersDto>(defaultFilters);

  const newApiPathList = { ...apiPaths.auditActions };
  const newApiPathDownload = { ...apiPaths.downloadAuditActions };

  const params = getQueryParamFromObject(defaultFilters);
  newApiPathList.params = params;
  newApiPathDownload.params = params;

  const [apiPathList, setApiPathList] = useState(newApiPathList);
  const [apiPathDownload, setApiPathDownload] = useState({
    ...newApiPathDownload,
    downloadFileName: `${t(
      keys.ACTION_DOWNLOAD_FILENAME,
    )}_${new Date().toISOString()}.xlsx`,
  });
  
  const now: number = Date.parse((new Date()).toString());

  const columns: IErgColumnType<AuditActionDto>[] = [
    {
      isSortable: false,
      title: t(keys.DETAIL),
      dataPropertyName: "id",

      render: (id) => (
        <>
          {
            <Button
              type="primary"
              icon={<ZoomInOutlined />}
              onClick={() => {
                setSelectedActionId(id);
                setIsDetailModalOpen(true);
              }}
            ></Button>
          }
        </>
      ),
    },
    {
      isSortable: false,
      title: t(keys.ACTION_REFERENCE),
      dataPropertyName: "accessDbId",
      render: (text, record) => (
        <>
          <div className={ (Date.parse(record.actionExpiryDate) <= now && record.status == ActionStatus.open) ? "expiryStyle" : "progressStyle" }>
            {record.accessDbId}
          </div>
        </>
      ),
    },
    {
      isSortable: false,
      title: t(keys.ACTION_NUMBER),
      dataPropertyName: "actionNumber",
    },

    { isSortable: false, title: t(keys.AUDIT), dataPropertyName: "audit" },
    { isSortable: false, title: t(keys.TITLE), dataPropertyName: "title" },

    {
      isSortable: false,
      title: t(keys.OBSERVATIONTYPE),
      dataPropertyName: "observationType",
      render: (text) => t(text.toUpperCase()),
    },

    {
      isSortable: false,
      title: t(keys.OWNER),
      dataPropertyName: "businessUnitLeader",
      render: (text, record) => (
        <>
          <div style={{ whiteSpace: "nowrap" }}>
            {record.businessUnitLeader.fullName}
          </div>
        </>
      ),
    },

    {
      isSortable: false,
      title: t(keys.STATUS),
      dataPropertyName: "status",
      render: (text) => (
        <div style={{ whiteSpace: "nowrap" }}>
          {t(`STATUS_${text.toUpperCase()}`)}
        </div>
      ),
    },
    {
      isSortable: false,
      title: t(keys.EXPIRATION_DATE),
      dataPropertyName: "actionExpiryDate",
      render: (text) => <>{FormatDateString(text)}</>,
    },
    {
      isSortable: false,
      title: t(keys.RISK),
      dataPropertyName: "criticalityDegree",
      render: (text) => <>{t(`${text.toUpperCase()}`)}</>,
    },
    {
      isSortable: false,
      title: t(keys.CRITICALITY),
      dataPropertyName: "criticalityTitle",
    },
    {
      isSortable: false,
      title: t(keys.IS_ACTION_DELEGATED),
      dataPropertyName: "actionDelegates",
      render: (delegates, record) => (
        <div style={{ textAlign: "center" }}>
          {isActionDelegatedToOthers(
            delegates as ActionDelegateDto[],
            record,
            currUser,
          ) ? (
            <Checkbox className="defaultCursor" checked />
          ) : (
            <Checkbox className="defaultCursor" checked={false} />
          )}
        </div>
      ),
    },
    {
      isSortable: false,
      title: t(keys.IS_ACTION_ASSIGNED),
      dataPropertyName: "actionDelegates",
      render: (delegates) => (
        <div style={{ textAlign: "center" }}>
          {isActionAssignedToUser(
            delegates as ActionDelegateDto[],
            currUser,
          ) ? (
            <Checkbox className="defaultCursor" checked />
          ) : (
            <Checkbox className="defaultCursor" checked={false} />
          )}
        </div>
      ),
    },
    {
      isSortable: false,
      title: t(keys.PLANNEDACTION),
      dataPropertyName: "plannedAction",
      render: (text) => (
        <div style={{ maxHeight: "100px", overflow: "scroll" }}>{text}</div>
      ),
    },
  ];

  if (isAdmin) {
    columns.splice(1, 0,
    {
      isSortable: false,
      title: t(keys.DELETE),
      dataPropertyName: "id",

      render: (id) => (
        <>
          {
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => {
                setSelectedActionId(id);
                setIsDeleteModalOpen(true);
              }}
            ></Button>
          }
        </>
      ),
    });
  }

  const handleOk = () => {
    setIsDetailModalOpen(false);
  };

  const handleOkCreate = () => {
    setIsCreateModalOpen(false);
    notificationapi.info({
      message: t(keys.OPERATION_SUCCESS),
      placement: "bottom",
    });
    setApiPathList({ ...apiPathList });
  };

  const handleOkDelete = () => {
    setIsDeleteModalOpen(false);
    notificationapi.info({
      message: t(keys.OPERATION_SUCCESS),
      placement: "bottom",
    });
    setApiPathList({ ...apiPathList });
  };

  const handleCancel = () => {
    setSelectedActionId(undefined);
    setIsDetailModalOpen(false);
  };
  const handleCancelCreate = () => {
    setIsCreateModalOpen(false);
  };
  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const onFinish = (values: any) => {
    console.log(values);
    const filters: AuditActionFiltersDto = {
      accessDbId: values.accessDbId,
      actionExpiryDateFrom: values.expirationDate?.[0]?.toISOString(),
      actionExpiryDateTo: values.expirationDate?.[1]?.toISOString(),
      isDelegated: values.hasBeenDelegated,
      hasReceivedAsDelegate: values.hasReceivedAsDelegate,
      status: values.status,
      criticalityDegrees:
        values.criticalityDegree === undefined
          ? undefined
          : [values.criticalityDegree],
      observationType:
        values.observationType === undefined
          ? undefined
          : [values.observationType],
      businessUnit:
        values.businessUnit === undefined ? undefined : [values.businessUnit],
      businessUnitLeaderPrincipalName:
        values.owner === undefined ? undefined : [values.owner],
    };
    const newApiPathList = { ...apiPathList };
    const newApiPathDownload = { ...apiPathDownload };

    const params = getQueryParamFromObject(filters);
    newApiPathList.params = params;
    newApiPathDownload.params = params;

    setApiPathList(newApiPathList);
    setApiPathDownload(newApiPathDownload);
    setFilters(filters);
  };

  const onViewExpired = (values: any) => {
    console.log(values);
    var dateStart = new Date(2000, 0, 1)
    const expiredStatus = [];
    expiredStatus.push(ActionStatus.open);
    const filters: AuditActionFiltersDto = {
      accessDbId: values.accessDbId,
      actionExpiryDateFrom: dayjs(dateStart).toISOString(),
      actionExpiryDateTo: dayjs().toISOString(),
      isDelegated: values.hasBeenDelegated,
      hasReceivedAsDelegate: values.hasReceivedAsDelegate,
      status: expiredStatus,
      criticalityDegrees:
        values.criticalityDegree === undefined
          ? undefined
          : [values.criticalityDegree],
      observationType:
        values.observationType === undefined
          ? undefined
          : [values.observationType],
      businessUnit:
        values.businessUnit === undefined ? undefined : [values.businessUnit],
      businessUnitLeaderPrincipalName:
        values.owner === undefined ? undefined : [values.owner],
      };
    const newApiPathList = { ...apiPathList };
    const newApiPathDownload = { ...apiPathDownload };

    const params = getQueryParamFromObject(filters);
    newApiPathList.params = params;
    newApiPathDownload.params = params;

    setApiPathList(newApiPathList);
    setApiPathDownload(newApiPathDownload);
    setFilters(filters);
  };

  return (
    <>
      <AuditActionTableFilters
        onFinish={onFinish}
        onViewExpired={onViewExpired}
        downloadButton={
          <>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => DownloadFileQuery(apiPathDownload, i18n.language)}
            >
              {t(keys.DOWNLOAD)}
            </Button>
            {isAdmin && (
              <Button
                type="primary"
                icon={<FileAddOutlined />}
                onClick={() => setIsCreateModalOpen(true)}
              >
                {t(keys.CREATE_NEW_ACTION)}
              </Button>
            )}
            <Tag style={{fontSize:14,paddingTop:2,paddingBottom:2}} color={colors.ergdarkblue}>{t(keys.ACTION_NUMBER_COUNT)} {count}</Tag>
          </>
        }
      />
      <div>
        <ErgDataTable<AuditActionDto>
          apiPath={apiPathList}
          apiTableQueryMethod={GetErgTableDataAsync}
          key={"id"}
          columns={columns}
          setCount={setCount}
        />
      </div>
      <Modal
        title={<div style={{ fontSize: "25px" }}>{t(keys.ACTION_DETAIL)}</div>}
        open={isDetailModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={`${modalWidth}%`}
        centered
      >
        <ActionDetail
          setModalWidth={setModalWidth}
          filters={filters}
          currentActionId={selectedActionId}
          setCurrentActionId={setSelectedActionId}
        />
      </Modal>

      <Modal
        title={<div style={{ fontSize: "25px" }}>{t(keys.ACTION_CREATE)}</div>}
        open={isCreateModalOpen}
        onOk={handleOkCreate}
        onCancel={handleCancelCreate}
        footer={null}
        width={`90%`}
        centered
      >
        <ActionCreation
          handleOk={handleOkCreate}
          handleCancel={handleCancelCreate}
        />
      </Modal>

      <Modal
        title={<div style={{ fontSize: "25px" }}>{t(keys.ACTION_DELETE)}</div>}
        open={isDeleteModalOpen}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        footer={null}
        width={`30%`}
        centered
      >
        <ActionDelete
          handleOk={handleOkDelete}
          handleCancel={handleCancelDelete}
          currentActionId={selectedActionId}
          setCurrentActionId={setSelectedActionId}
        />
      </Modal>
    </>
  );
}

export default AuditActionTable;
