import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { keys } from "../../../resources/translations/keys";
import ErgButton from "../../ui/ErgButton/ErgButton";

function SignInButton(): JSX.Element {
  const { t } = useTranslation();
  const { instance } = useMsal();

  function login(): void {
    instance.loginRedirect();
  }

  return (
    <ErgButton onClick={login} type={"green"}>
      {t(keys.SIGN_IN)}
    </ErgButton>
  );
}

export default SignInButton;
