import { AuditLogBaseDto } from "../dtos/AuditLogBaseDto";
import { putDataAsync } from "../functions/apiMethods";
import apiPaths from "../resources/api/apiPaths";

export async function CreateAuditLog(AuditLogDto: AuditLogBaseDto) {
  var result = await putDataAsync<AuditLogBaseDto>({
    apiPath: apiPaths.createAuditLog.path,
    body: AuditLogDto,
  });
}
