import { useTranslation } from "react-i18next";
import HttpStatusCode from "../../../../enumerations/HttpStatusCode";
import i18n from "../../../../resources/translations/i18n";
import { keys } from "../../../../resources/translations/keys";

interface IHttpErrorMessageProps {
  code: HttpStatusCode;
}

function HttpErrorMessage(props: IHttpErrorMessageProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <div className="erg-letter-spacing-3 erg-font-enormous erg-bold">
        {i18n.exists(`ERROR_HTTP_${props.code}`) && props.code}
      </div>
      <div className="erg-letter-spacing-1">
        {i18n.exists(`ERROR_HTTP_${props.code}`)
          ? t(`ERROR_HTTP_${props.code}`)
          : t(keys.ERROR_GENERIC)}
      </div>
    </>
  );
}

export default HttpErrorMessage;
