import {
  // DeleteFilled,
  // DeleteOutlined,
  // EditOutlined,
  ClearOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  FormInstance,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import { colors } from "../../../style/variables";
const { Panel } = Collapse;
const layout = {
  wrapperCol: { span: 15 },
};
const tailLayout = {
  wrapperCol: { span: 16 },
};

interface LoginTableFiltersProps {
  onFinish: (values: any) => void;
  downloadButton: JSX.Element;
}
const { RangePicker } = DatePicker;

function LoginTableFilters(props: LoginTableFiltersProps): JSX.Element {
  const { t } = useTranslation();
  const [auditedUsers, setAuditedUsers] = useState<string[]>();
  const formRef = React.useRef<FormInstance>(null);

  const onReset = () => {
    formRef.current?.resetFields();
  };
  const query = useQuery({
    queryKey: [apiPaths.getAuditedUsers],
    queryFn: () => GetErgTableDataAsync(apiPaths.getAuditedUsers),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: async (data: IErgDataTableResponse<string>) =>
      await parseAndSetData(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const parseAndSetData = async (data: IErgDataTableResponse<string>) => {
    setAuditedUsers(data.items);
  };

  const auditedUsersOption = auditedUsers?.map((b) => {
    return <Select.Option value={b}>{b}</Select.Option>;
  });
  return (
    <>
      <div style={{ marginTop: "2vw", marginRight: "40px" }}>
        <Form
          {...layout}
          ref={formRef}
          name="control-ref"
          onFinish={props.onFinish}
          size="middle"
          style={{ marginTop: "10" }}
        >
          <Collapse
            style={{
              marginBottom: "20px",
              marginLeft: "20px",
              marginRight: "20px",
              width: "100%",
            }}
            onChange={() => {}}
          >
            <Panel
              style={{
                width: "100%",
                background: colors.erggreen,
                color: "white",
              }}
              header={<span style={{ color: "white" }}>{t(keys.FILTERS)}</span>}
              key={"1"}
            >
              <Row>
                <Col span={12}>
                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="user"
                    label={t(keys.USER)}
                  >
                    <Select mode="multiple" allowClear>
                      {auditedUsersOption}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    style={{ textAlign: "center" }}
                    name="accessLogDate"
                    label={t(keys.DATE_RANGE)}
                  >
                    <RangePicker
                      allowEmpty={[true, true]}
                      style={{ width: "100%" }}
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Form.Item {...tailLayout} style={{ marginLeft: "20px" }}>
            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                htmlType="submit"
              >
                {t(keys.SEARCH)}
              </Button>
              <Button
                icon={<ClearOutlined />}
                htmlType="button"
                onClick={onReset}
              >
                {t(keys.RESET)}
              </Button>
              {props.downloadButton}
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default LoginTableFilters;
