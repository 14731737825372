import { getDataAsync } from "../functions/apiMethods";
import { getQueryParamFromObject } from "../functions/utils";
import apiPaths from "../resources/api/apiPaths";

export async function checkActionAccessDbExist(id: number) {
  const params = getQueryParamFromObject({
    id: id,
  });
  var result = await getDataAsync<boolean>({
    apiPath: apiPaths.checkActionAccessDbExist.path,
    queryparams: params,
  });
  return result;
}
