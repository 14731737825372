import { Statistic } from "antd";
import "./Counter.scss";
interface CounterProps {
  title: string;
  count: number;
}

function Counter(props: CounterProps): JSX.Element {
  return (
    <>
      <Statistic
        style={{
          textAlign: "center",
          padding: 5,
          width: "170px",
        }}
        valueStyle={{ fontWeight: "bold", fontSize: "50px" }}
        title={props.title}
        value={props.count}
      />
    </>
  );
}

export default Counter;
