export const keys = {
  BACK_TO_HOME: "BACK_TO_HOME",
  ERROR_GENERIC: "ERROR_GENERIC",
  ERROR_HTTP_404: "ERROR_HTTP_404",
  ERROR_SIGN_IN: "ERROR_SIGN_IN",
  HOME: "HOME",
  MENU: "MENU",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  REFRESH: "REFRESH",
  SECURITY: "SECURITY",
  TEST: "TEST",
  USERS: "USERS",
  AUDIT: "AUDIT",
  STATUS: "STATUS",
  EXPIRATION_DATE: "EXPIRATION_DATE",
  RISK: "RISK",
  CRITICALITY: "CRITICALITY",
  IS_ACTION_DELEGATED: "IS_ACTION_DELEGATED",
  IS_ACTION_ASSIGNED: "IS_ACTION_ASSIGNED",
  CREATION_DATE: "CREATION_DATE",
  ACTION_DESCRIPTION: "ACTION_DESCRIPTION",
  ACTION_NUMBER: "ACTION_NUMBER",
  AUDITACTION_CRITICALITY_GREEN: "AUDITACTION_CRITICALITY_GREEN",
  ACTION_EXPIRY_DATE_RANGE: "ACTION_EXPIRY_DATE_RANGE",
  DELEGATED_ACTIONS: "DELEGATED_ACTIONS",
  RECEIVED_AS_DELEGATE_ACTIONS: "RECEIVED_AS_DELEGATE_ACTIONS",
  OWNER: "OWNER",
  BUSINESS_UNIT: "BUSINESS_UNIT",
  STATUS_OPEN: "STATUS_OPEN",
  STATUS_CLOSED: "STATUS_CLOSED",
  GREEN: "GREEN",
  YELLOW: "YELLOW",
  RED: "RED",
  SEARCH: "SEARCH",
  RESET: "RESET",
  OWNED_ACTIONS: "OWNED_ACTIONS",
  ALERTS: "ALERTS",
  YES: "YES",
  NO: "NO",
  ACTION_DOWNLOAD_FILENAME: "ACTION_DOWNLOAD_FILENAME",
  DOWNLOAD: "DOWNLOAD",
  ACTION_DETAIL: "ACTION_DETAIL",
  ACTION_DELETE: "ACTION_DELETE",
  CONFIRM_DELETE_MSG: "CONFIRM_DELETE_MSG",
  ALERT_MESSAGES: "ALERT_MESSAGES",
  MANAGE_ALERT_MESSAGES: "MANAGE_ALERT_MESSAGES",
  ACTION_LIST: "ACTION_LIST",
  ACCESS_LOG: "ACCESS_LOG",
  ACCESS_LOG_DOWNLOAD_FILENAME: "ACCESS_LOG_DOWNLOAD_FILENAME",
  ACTIONS_DELETED_LOG_DOWNLOAD_FILENAME: "ACTIONS_DELETED_LOG_DOWNLOAD_FILENAME",
  USER: "USER",
  DATE: "DATE",
  DETAIL: "DETAIL",
  UNDO: "UNDO",
  CONFIRM_DELETE: "CONFIRM_DELETE",
  NEW: "NEW",
  OPERATION_SUCCESS: "OPERATION_SUCCESS",
  DELETE: "DELETE",
  ID: "ID",
  OPEN_DETAIL: "OPEN_DETAIL",
  DESCRIPTION: "DESCRIPTION",
  SAVE: "SAVE",
  SEND: "SEND",
  MANDATORY_FIELD: "MANDATORY_FIELD",
  DELEGATE_ACTION: "DELEGATE_ACTION",
  VOID_DELEGATE: "VOID_DELEGATE",
  REQUEST_POSTPONE: "REQUEST_POSTPONE",
  REQUEST_CLOSE: "REQUEST_CLOSE",
  DELEGATE: "DELEGATE",
  NOTE: "NOTE",
  MANAGEMENT_COMMENT: "MANAGEMENT_COMMENT",
  CORRECTIVE_ACTION: "CORRECTIVE_ACTION",
  DATE_RANGE: "DATE_RANGE",
  HISTORYLOG: "HISTORYLOG",
  DELAY_REQUEST: "DELAY_REQUEST",
  CLOSE_REQUEST: "CLOSE_REQUEST",
  DELEGATE_ACTION_UNDO: "DELEGATE_ACTION_UNDO",
  UPLOAD_FILES: "UPLOAD_FILES",
  CONFIRM_UNDO_DELEGATE: "CONFIRM_UNDO_DELEGATE",
  FILTERS: "FILTERS",
  CRITICITY: "CRITICITY",
  IMPROVEMENTAREA: "IMPROVEMENTAREA",
  ATTENTION_THEME: "ATTENTION_THEME",
  STATUS_NA: "STATUS_NA",
  STATUS_NOTIMPLEMENTED: "STATUS_NOTIMPLEMENTED",
  VIEW_ACTIONS: "VIEW_ACTIONS",
  ACCESSES: "ACCESSES",
  OBSERVATIONTYPE: "OBSERVATIONTYPE",
  ACTION_CREATE: "ACTION_CREATE",
  ACCESSDBID: "ACCESSDBID",
  YEAR: "YEAR",
  COMPANY: "COMPANY",
  CRITICALITYTITLE: "CRITICALITYTITLE",
  TITLE: "TITLE",
  OBSERVATION: "OBSERVATION",
  ADVICE: "ADVICE",
  MANAGEMENTCOMMENT: "MANAGEMENTCOMMENT",
  BU_COMPANY_AREA: "BU_COMPANY_AREA",
  PLANNEDACTION: "PLANNEDACTION",
  EXPIRY: "EXPIRY",
  ACTIONOUTCOME: "ACTIONOUTCOME",
  IAMANAGER: "IAMANAGER",
  IAMANAGER2: "IAMANAGER2",
  IAMANAGER3: "IAMANAGER3",
  RIL626: "RIL626",
  RIL231: "RIL231",
  FINDINGS: "FINDINGS",
  CREATE_NEW_ACTION: "CREATE_NEW_ACTION",
  ADD_OWNER: "ADD_OWNER",
  ID_ALREADY_PRESENT: "ID_ALREADY_PRESENT",
  SELECT_USER_TO_DELEGATE: "SELECT_USER_TO_DELEGATE",
  ACTION_REFERENCE: "ACTION_REFERENCE",
  OPEN_DELEGATED_ACTIONS: "OPEN_DELEGATED_ACTIONS",
  ACTION_NUMBER_COUNT:"ACTION_NUMBER_COUNT",
  CRITICITY_CARD:"CRITICITY_CARD",
  VIEW_EXPIRED_ACTIONS: "VIEW_EXPIRED_ACTIONS",
  PRIORITY: "PRIORITY",
  DELETE_LOG: "DELETE_LOG",
  ACTION_ID: "ACTION_ID"
};
