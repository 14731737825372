import { NewBusinessUnitDto } from "../dtos/NewBusinessUnitDto";
import { postDataAsync } from "../functions/apiMethods";
import apiPaths from "../resources/api/apiPaths";

export async function upsertBusinessUnit(
  newBu: NewBusinessUnitDto,
): Promise<number> {
  var result = await postDataAsync<NewBusinessUnitDto, number>({
    apiPath: apiPaths.upsertBusinessUnit.path,
    body: newBu,
  });
  return result;
}
