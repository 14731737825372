import { Button, ButtonProps } from "antd";
import "./ErgButton.scss";

type PickedProps =
  | "className"
  | "children"
  | "onClick"
  | "loading"
  | "disabled"
  | "icon";

export type ErgButtonType = "dark-blue" | "light-blue" | "green";

interface IErgButtonProps extends Pick<ButtonProps, PickedProps> {
  type?: ErgButtonType;
}

function ErgButton(props: IErgButtonProps): JSX.Element {
  return (
    <Button
      {...props}
      type={"default"}
      className={`erg-button erg-button-${
        props.type ?? "dark-blue"
      } erg-text-uppercase ${props.className}`}
    />
  );
}

export default ErgButton;
