import HttpStatusCode from "../../enumerations/HttpStatusCode";
import { get } from "../../functions/utils";
import IRoute from "../../interfaces/IRoute";
import { ErrorRouteParam, ErrorType } from "../../types";
import { keys } from "../translations/keys";

export type RouteKey =
  | "home"
  | "index"
  | "error"
  | "test"
  | "fallback"
  | "httpNotFoundError"
  | "security_users"
  | "alertMessages"
  | "actionList"
  | "accesslog"
  | "deletelog";

const routes: { [key in RouteKey]: IRoute } = {
  error: { path: "/error" },
  fallback: { path: "/*" },
  home: { path: "/home", keys: [keys.HOME] },
  httpNotFoundError: {
    path: `/error?${get<ErrorRouteParam>("type")}=${get<ErrorType>(
      "http",
    )}&${get<ErrorRouteParam>("code")}=${HttpStatusCode.NotFound}`,
  },
  index: { path: "/", keys: [keys.HOME] },
  security_users: {
    path: "/security/users",
    keys: [keys.SECURITY, keys.USERS],
  },
  test: { path: "/test", keys: [keys.TEST] },
  alertMessages: { path: "/alerts", keys: [keys.ALERT_MESSAGES] },
  actionList: { path: "/actions", keys: [keys.ACTION_LIST] },
  accesslog: { path: "/accesslog", keys: [keys.ACCESS_LOG] },
  deletelog: { path: "/actionsdeletedlog", keys: [keys.DELETE_LOG] },
};

export default routes;
