import AuditActionTable from "../../data/AuditActionTable/AuditActionTable";

function ActionListPage(): JSX.Element {
  return (
    <>
      <AuditActionTable />
    </>
  );
}

export default ActionListPage;
