import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditLogDto } from "../../../dtos/AuditLogDto";
import { AuditLogFiltersDto } from "../../../dtos/AuditLogFiltersDto";
import { LogType } from "../../../enumerations/LogType";
import {
  FormatDateString,
  getQueryParamFromObject,
} from "../../../functions/utils";
import IErgColumnType from "../../../interfaces/IErgDataTableColumnType";
import { DownloadFileQuery } from "../../../queries/downloadFileQuery";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import AuditLogTable from "../AuditLogTable/AuditLogTable";
import LoginTableFilters from "./LoginTableFilters";
interface LoginTableProps {
  pagesize?: number;
  showFilters?: boolean;
}

function LoginTable(props: LoginTableProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<AuditLogFiltersDto>({
    logType: LogType.Login,
  });
  const [apiPathDownload, setApiPathDownload] = useState({
    ...apiPaths.downloadAccessLogs,
    downloadFileName: `${t(
      keys.ACCESS_LOG_DOWNLOAD_FILENAME,
    )}_${new Date().toISOString()}.xlsx`,
  });
  const columns: IErgColumnType<AuditLogDto>[] = [
    {
      title: t(keys.USER),
      dataPropertyName: "notes",
      isSortable: false,
    },
    {
      title: t(keys.DATE),
      dataPropertyName: "createdOn",
      render: (text) => <>{FormatDateString(text, true)}</>,
      isSortable: false,
      defaultSortOrder: "descend",
    },
  ];

  const onFiltersSet = (values: any) => {
    const filters: AuditLogFiltersDto = {
      notes: values.user,
      accessLogFrom: values.accessLogDate?.[0].toISOString(),
      accessLogTo: values.accessLogDate?.[1].toISOString(),
      logType: LogType.Login,
    };
    const newApiPathDownload = { ...apiPathDownload };

    const params = getQueryParamFromObject(filters);
    newApiPathDownload.params = params;

    setApiPathDownload(newApiPathDownload);
    setFilters(filters);
  };

  return (
    <>
      {props.showFilters && (
        <LoginTableFilters
          onFinish={onFiltersSet}
          downloadButton={
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={() => DownloadFileQuery(apiPathDownload, i18n.language)}
            >
              {t(keys.DOWNLOAD)}
            </Button>
          }
        />
      )}
      <AuditLogTable
        pagesize={props.pagesize}
        columns={columns}
        filters={filters}
      />
    </>
  );
}

export default LoginTable;
