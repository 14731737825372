import { SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Form, Input, Space } from "antd";
import dayjs from "dayjs";
import { StringMap } from "quill";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AlertMessageDto } from "../../../dtos/AlertMessageDto";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
interface AdminAlertDetailProps {
  alertMessage?: AlertMessageDto;
  onFinish: (values: any) => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

function AdminAlertDetail(props: AdminAlertDetailProps): JSX.Element {
  const { t } = useTranslation();
  const [apiPathList, setApiPathList] = useState(apiPaths.auditActions);
  const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";
  const [form] = Form.useForm();
  const modules: StringMap = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "super" }, { script: "sub" }],
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["direction", { align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const onReset = () => {
    form.resetFields();
  };
  const initialValues = {
    ...props.alertMessage,
    expiryDate: dayjs(props.alertMessage?.expiryDate) ?? dayjs(),
  };

  useEffect(() => {
    const initialValues = {
      ...props.alertMessage,
      expiryDate: dayjs(props.alertMessage?.expiryDate) ?? dayjs(),
    };
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, props.alertMessage]);
  return (
    <div>
      <Form
        {...layout}
        name="control-ref"
        onFinish={props.onFinish}
        size="middle"
        form={form}
        initialValues={{
          ...props.alertMessage,
          expiryDate: dayjs(props.alertMessage?.expiryDate) ?? dayjs(),
        }}
      >
        <Space style={{ display: "flex" }} direction="vertical" size={"middle"}>
          <Form.Item
            style={{ textAlign: "center" }}
            name="description"
            label={t(keys.DESCRIPTION)}
            rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ textAlign: "center" }}
            name="expiryDate"
            label={t(keys.EXPIRATION_DATE)}
            rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
          >
            <DatePicker style={{ display: "flex" }} format={"DD/MM/YYYY"} />
          </Form.Item>
        </Space>
        <Form.Item name="message" noStyle>
          <ReactQuill modules={modules} theme="snow" />
        </Form.Item>
        <Form.Item
            style={{ marginTop: "10px" }}
            name="priority"
            valuePropName="checked"
            label={t(keys.PRIORITY)}
          >
          <Checkbox defaultChecked={false} />
        </Form.Item>
        <Form.Item style={{ marginTop: "10px" }} {...tailLayout}>
          <Space direction="horizontal" size={"middle"}>
            <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
              {t(keys.SAVE)}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AdminAlertDetail;
