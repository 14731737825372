import { AuditActionDto } from "../dtos/AuditActionDto";
import { putDataAsync } from "../functions/apiMethods";
import apiPaths from "../resources/api/apiPaths";

export async function createAction(auditActionDto: AuditActionDto) {
  var result = await putDataAsync<AuditActionDto>({
    apiPath: apiPaths.createAction.path,
    body: auditActionDto,
  });
}
