import {
  ExclamationOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import useAuthorization from "../../../hooks/useAuthorization";
import claims from "../../../resources/auth/claims";
import routes from "../../../resources/navigation/routes";
import { keys } from "../../../resources/translations/keys";
import ActionCounters from "../../data/ActionCounter/ActionCounter";
import AdminAlerts from "../../data/AdminAlerts/AdminAlerts";
import LoginTable from "../../data/LoginTable/LoginTable";
function HomePage(): JSX.Element {
  const { t } = useTranslation();
  const [isUserAuthorized, isAuthenticated, user] = useAuthorization();
  return (
    <div style={{ textAlign: "center" }}>
      <Space
        style={{ width: "100%", marginLeft: "10px" }}
        direction="horizontal"
      >
        <Card
          title={t(keys.ALERTS)}
          headStyle={{ fontSize: "30px" }}
          style={{ minWidth: "35vw" }}
        >
          <AdminAlerts />
          <div
            style={{
              display: "block",
              textAlign: "center",
              margin: "10px 0 0 10px",
            }}
          >
            <Space direction="vertical" size={"large"}>
              <ActionCounters />

              <NavLink to={routes.actionList.path}>
                <Button
                  icon={<UnorderedListOutlined />}
                  style={{ height: "100%" }}
                  type="primary"
                >
                  {t(keys.VIEW_ACTIONS)}
                </Button>
              </NavLink>
            </Space>
          </div>
        </Card>
        {isUserAuthorized([claims.groups.admin]) && (
          <Card
            title={t(keys.ACCESSES)}
            headStyle={{ fontSize: "30px" }}
            style={{ maxWidth: "45vw", minWidth: "35vw" }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              <Row>
                <Col>
                  <div>
                    <LoginTable pagesize={5} />
                  </div>
                </Col>
                <Col style={{ textAlign: "center" }}>
                  <Space
                    style={{ marginTop: "50%" }}
                    size="large"
                    direction="vertical"
                  >
                    <NavLink to={routes.alertMessages.path}>
                      <Button
                        icon={<ExclamationOutlined />}
                        style={{ height: "100%", width: "200px" }}
                        type="primary"
                      >
                        {t(keys.MANAGE_ALERT_MESSAGES)}
                      </Button>
                    </NavLink>
                    <NavLink to={routes.accesslog.path}>
                      <Button
                        icon={<UserOutlined />}
                        style={{ height: "100%", width: "200px" }}
                        type="primary"
                      >
                        {t(keys.ACCESS_LOG)}
                      </Button>
                    </NavLink>
                  </Space>
                </Col>
              </Row>
            </div>
          </Card>
        )}
      </Space>
    </div>
  );
}

export default HomePage;
