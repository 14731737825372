import LoginTable from "../../data/LoginTable/LoginTable";

function AccessLogPage(): JSX.Element {
  return (
    <>
      <LoginTable showFilters pagesize={10} />
    </>
  );
}

export default AccessLogPage;
