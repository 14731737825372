import {
  ExclamationOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  UserOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { TFunction } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getRouteKeys } from "../../../../functions/navigation";
import useAuthorization from "../../../../hooks/useAuthorization";
import IBaseComponentProps from "../../../../interfaces/IBaseComponentProps";
import claims from "../../../../resources/auth/claims";
import routes from "../../../../resources/navigation/routes";
import { keys } from "../../../../resources/translations/keys";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import ErgLayoutNavLink from "../ErgLayoutNavLink/ErgLayoutNavLink";
import "./ErgSider.scss";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  isUserAuthorized: boolean,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem | null {
  return isUserAuthorized
    ? ({
        key,
        icon,
        children,
        label,
        type,
      } as MenuItem)
    : null;
}

function getMenuItems(
  isUserAuthorized: (claimNames: string[]) => boolean,
  t: TFunction<"translation", undefined>,
): ItemType[] {
  const handleAlertMessageItems: ItemType[] = [
    getItem(
      <ErgLayoutNavLink
        to={routes.alertMessages.path}
        className="erg-text-capitalized"
      >
        {t(keys.MANAGE_ALERT_MESSAGES)}
      </ErgLayoutNavLink>,
      isUserAuthorized([claims.groups.admin]),
      keys.ALERT_MESSAGES,
      <UserOutlined />,
    ),
  ].filter((i) => !!i);

  return [
    getItem(
      <ErgLayoutNavLink to={routes.home.path} className="erg-text-capitalized">
        {t(keys.HOME)}
      </ErgLayoutNavLink>,
      true,
      keys.HOME,
      <HomeOutlined />,
    ),
    getItem(
      <ErgLayoutNavLink
        to={routes.actionList.path}
        className="erg-text-capitalized"
      >
        {t(keys.ACTION_LIST)}
      </ErgLayoutNavLink>,
      true,
      keys.ACTION_LIST,
      <UnorderedListOutlined />,
    ),
    getItem(
      <ErgLayoutNavLink
        to={routes.alertMessages.path}
        className="erg-text-capitalized"
      >
        {t(keys.MANAGE_ALERT_MESSAGES)}
      </ErgLayoutNavLink>,
      isUserAuthorized([claims.groups.admin]),
      keys.ALERT_MESSAGES,
      <ExclamationOutlined />,
    ),
    getItem(
      <ErgLayoutNavLink
        to={routes.accesslog.path}
        className="erg-text-capitalized"
      >
        {t(keys.ACCESS_LOG)}
      </ErgLayoutNavLink>,
      isUserAuthorized([claims.groups.admin]),
      keys.ACCESS_LOG,
      <UserOutlined />,
    ),
    getItem(
      <ErgLayoutNavLink
        to={routes.deletelog.path}
        className="erg-text-capitalized"
      >
        {t(keys.DELETE_LOG)}
      </ErgLayoutNavLink>,
      isUserAuthorized([claims.groups.admin]),
      keys.DELETE_LOG,
      <DeleteOutlined />,
    ),
  ];
}

interface IErgSiderProps extends IBaseComponentProps {}

function ErgSider(props: IErgSiderProps): JSX.Element {
  const layoutContext = useContext(LayoutContext)!;
  const [items, setItems] = useState<ItemType[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([keys.HOME]);
  const [isUserAuthorized, isAuthenticated, user] = useAuthorization();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const menuItems = getMenuItems(isUserAuthorized, t);
    setItems(menuItems);
  }, [user]);

  useEffect(() => {
    let routeSelectedKeys = getRouteKeys(location.pathname);
    setSelectedKeys(routeSelectedKeys);
  }, [location.pathname]);

  return (
    <Sider
      className={`erg-sider ${props.className}`}
      collapsed={layoutContext.navMenu.isCollapsed}
      width={320}
      collapsedWidth={50}
    >
      <Menu
        className="erg-h-100 erg-scroll-y"
        mode="inline"
        selectedKeys={selectedKeys}
        items={items}
      />
    </Sider>
  );
}

export default ErgSider;
