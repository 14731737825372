import { AccountInfo } from "@azure/msal-common";
import { ActionDelegateDto } from "../dtos/ActionDelegateDto";
import { AuditActionDto } from "../dtos/AuditActionDto";

export function get<TTypedString>(t: TTypedString): TTypedString {
  return t;
}

export function isActionAssignedToUser(
  delegates: ActionDelegateDto[],
  user?: AccountInfo,
): boolean {
  if (!user) {
    return false;
  }
  const isDelegated = isActionDelegated(delegates);
  if (!isDelegated) {
    return false;
  }
  const isAssignedToUser = delegates.some(
    (d) => d.delegatedUserPrincipalName === user.username,
  );
  return isAssignedToUser;
}

export function isActionDelegatedToOthers(
  delegates: ActionDelegateDto[],
  auditAction: AuditActionDto,
  user?: AccountInfo,
): boolean {
  const isDelegated = delegates.some(
    (d) => !d.isVoided && d.delegatedUserPrincipalName !== user?.username,
  );

  return isDelegated;
}
export function isActionDelegated(delegates: ActionDelegateDto[]): boolean {
  return delegates.some((d) => !d.isVoided);
}
export function FormatDateString(date: string, withTime?: boolean) {
  const dateObj = new Date(date);

  let retval = dateObj.toLocaleDateString('it-IT', {
    month: '2-digit',day: '2-digit',year: 'numeric'});
  if (withTime) {
    retval += " " + dateObj.toLocaleTimeString("it-IT");
  }
  return retval;
}
export function getQueryParamFromObject(obj?: object): string[] {
  if (!obj) {
    return [];
  }
  const params = Object.entries(obj)
    .filter((element) => {
      //Discard undefined values
      if (element[1] === undefined) {
        return false;
      }
      return true;
    })
    .map((element) => {
      //If it is an array extract multiple paths
      if (Array.isArray(element[1]?.[0])) {
        const arrayStrings = element[1]?.[0].map((arrayElem) => {
          return `${element[0]}=${encodeURIComponent(arrayElem)}`;
        });
        return arrayStrings.join("&");
      }
      return `${element[0]}=${encodeURIComponent(element[1])}`;
    });
  return params;
}

export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}
