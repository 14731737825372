import IBaseComponentProps from "../../../interfaces/IBaseComponentProps";
import "./ErgLogo.scss";

type LogoType = "light" | "dark";

interface IErgLogoProps extends IBaseComponentProps {
  type?: LogoType;
}

function ErgLogo(props: IErgLogoProps): JSX.Element {
  return (
    <div className={props.className}>
      <img
        src={props.type === "dark" ? "/logo_2.png" : "/logo_1.png"}
        className="erg-w-100 erg-h-100"
        alt="erg logo"
      />
    </div>
  );
}

export default ErgLogo;
