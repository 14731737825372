import { Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AuditActionDto } from "../../../dtos/AuditActionDto";
import { AuditActionFiltersDto } from "../../../dtos/AuditActionFiltersDto";
import { ActionStatus } from "../../../enumerations/ActionStatus";
import { getCurrUser } from "../../../functions/apiMethods";
import { getQueryParamFromObject } from "../../../functions/utils";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import Counter from "../Counter/Counter";

function ActionCounters(): JSX.Element {
  const { t } = useTranslation();

  const filters: AuditActionFiltersDto = {
    status: [ActionStatus.open],
  };
  const newApiPath = { ...apiPaths.auditActions };
  const params = getQueryParamFromObject(filters);

  newApiPath.params = params;

  const query = useQuery({
    queryKey: [newApiPath],
    queryFn: () => GetErgTableDataAsync(newApiPath),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: async (data: IErgDataTableResponse<AuditActionDto>) =>
      await parseAndSetData(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  const [ownerActionsNumber, setOwnerActionsNumber] = useState(0);
  const [delegateActionsNumber, setDelegateActionsNumber] = useState(0);

  const parseAndSetData = async (
    data: IErgDataTableResponse<AuditActionDto>,
  ) => {
    const currUser = await getCurrUser();
    const ownerActions = data.items.filter(
      (audit) => audit.businessUnitLeader.principalName === currUser.username,
    );
    const delegateActions = data.items.filter(
      (audit) =>
        audit.businessUnitLeader.principalName === currUser.username &&
        !!audit.actionDelegates &&
        audit.actionDelegates.length >= 1,
    );
    setOwnerActionsNumber(ownerActions.length);
    setDelegateActionsNumber(delegateActions.length);
  };
  return (
    <>
      <Space size={"large"}>
        <Counter title={t(keys.OWNED_ACTIONS)} count={ownerActionsNumber} />

        <Counter
          title={t(keys.OPEN_DELEGATED_ACTIONS)}
          count={delegateActionsNumber}
        />
      </Space>
    </>
  );
}

export default ActionCounters;
