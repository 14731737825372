import {
  // DeleteFilled,
  // DeleteOutlined,
  // EditOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ClockCircleOutlined,
  IssuesCloseOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Row, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditActionDto } from "../../../dtos/AuditActionDto";
import { AuditActionFiltersDto } from "../../../dtos/AuditActionFiltersDto";
import { AuditLogDto } from "../../../dtos/AuditLogDto";
import { AuditLogFiltersDto } from "../../../dtos/AuditLogFiltersDto";
import { AuditRequestsFilterDto } from "../../../dtos/AuditRequestsFilterDto";
import { ActionStatus } from "../../../enumerations/ActionStatus";
import Text from "antd/es/typography/Text";
import { RequestType } from "../../../enumerations/RequestType";
import {
  FormatDateString,
  getQueryParamFromObject,
} from "../../../functions/utils";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import { colors } from "../../../style/variables";
import AuthorizationContext from "../../contexts/AuthorizationContext/AuthorizationContext";
import ActionRequestForm from "../ActionRequestForm/ActionRequestForm";
import RequestsTable from "../RequestsTable/RequestsTable";
import "./ActionDetail.scss";
import useAuthorization from "../../../hooks/useAuthorization";
import claims from "../../../resources/auth/claims";
interface ActionDetailProps {
  filters?: AuditActionFiltersDto;
  currentActionId?: number;
  setModalWidth: (width: number) => void;
  setCurrentActionId: (actionId: number | undefined) => void;
}
const cardStyle = {
  borderStyle: "solid",
  borderWidth: "3px",
  borderColor: colors.erggreen,
  minHeight: "150px",
  overflow: "scroll",
  maxHeight: "150px",
};
const headStyle = {
  borderStyle: "solid",
  borderWidth: "3px",
  borderColor: colors.erggreen,
  background: colors.erggreen,
  color: "white",
  fontSize: "16px",
  width: "80%",
};
function ActionDetail(props: ActionDetailProps): JSX.Element {
  const authContext = useContext(AuthorizationContext);
  const { t, i18n } = useTranslation();
  const [currentActionId, setCurrentActionId] = useState(props.currentActionId);
  const [apiPathList, setApiPathList] = useState(apiPaths.auditActions);
  const [actions, setActions] = useState<AuditActionDto[]>([]);
  const [currentAction, setCurrentAction] = useState<AuditActionDto>();
  const currIndex = actions?.findIndex((a) => a.id === currentActionId);
  const isFirstElement = currIndex === 0;
  const isLastElement = actions?.length - 1 === currIndex;
  const [auditLogs, setAuditLogs] = useState<AuditLogDto[]>();
  const isDelegate = currentAction?.actionDelegates?.some(
    (a) => a.delegatedUserPrincipalName === authContext?.user?.username
  );
  const actionHasBeenDelegated = !!currentAction?.actionDelegates
    ? currentAction.actionDelegates.length > 0
    : false;
  const [isUserAuthorized, isAuthenticated, user] = useAuthorization();

  const [isShowingDetail, setIsShowingDetail] = useState(true);
  const [isShowingDelegateRequest, setIsShowingDelegateRequest] =
    useState(false);
  const [isShowingRequestForm, setIsShowingRequestForm] = useState(false);
  const [requestType, setRequestType] = useState<RequestType>();
  const [requestFilters, setRequestsFilter] = useState<AuditRequestsFilterDto>({
    auditActionId: props.currentActionId,
    language: i18n.language,
  });

  const parseAndSetData = async (data: IErgDataTableResponse<AuditLogDto>) => {
    setAuditLogs(data.items);
  };
  const isAdmin = isUserAuthorized([claims.groups.admin]);
  console.log(currentAction?.businessUnitLeader.principalName)
  const isOwner =
    currentAction?.businessUnitLeader.principalName ===
    authContext?.user?.username;
  useEffect(() => {
    const fetchActions = async () => {
      const params = getQueryParamFromObject(props.filters);
      const newApiPathList = { ...apiPathList };
      newApiPathList.params = params;
      const actions =
        await GetErgTableDataAsync<AuditActionDto>(newApiPathList);
      setActions(actions.items);
    };
    fetchActions().catch(console.error);
  }, [props.filters, apiPathList]);

  //On currentactionid change
  useEffect(() => {
    setCurrentActionId(props.currentActionId);
    const auditLogFiltersDto: AuditLogFiltersDto = {
      auditActionId: props.currentActionId,
    };

    setIsShowingDetail(true);
  }, [props.currentActionId]);
  //Handle currentactionId change
  useEffect(() => {
    const currentAction = actions?.find((a) => a.id === currentActionId);
    setCurrentAction(currentAction);
    //Set default visibility
    setIsShowingDelegateRequest(false);
    setIsShowingRequestForm(false);
    setIsShowingDetail(true);
    setRequestsFilter({
      auditActionId: currentActionId,
      language: i18n.language,
    });
  }, [actions, currentActionId]);

  const requestFormGoBack = () => {
    setIsShowingRequestForm(false);
    setIsShowingDetail(true);
    setCurrentActionId(currentActionId); //Force refresh
    setApiPathList({ ...apiPaths.auditActions });
    props.setModalWidth(90);
  };
  const requestsAvailable = currentAction?.status === ActionStatus.open
  return (
    <div style={{ marginLeft: "30px", marginRight: "30px" }}>
      {!!currentAction &&
        !!currentAction.id &&
        !!requestType &&
        isShowingRequestForm && (
          <ActionRequestForm
            goBack={requestFormGoBack}
            currentActionId={currentAction.id}
            currentAction={currentAction}
            requestType={requestType}
          />
        )}

      {!!currentAction && isShowingDetail && (
        <>
          <Row style={{ marginBottom: "10px" }} gutter={18}>
            <Col span={"24"}>
              <Descriptions
                bordered
                labelStyle={{
                  color: colors.ergdarkblue,
                  fontWeight: "bold",
                  fontSize: "18px",
                  width: "5%",
                  textDecoration: "underline",
                  background: "none",
                }}
                size="small"
                contentStyle={{ width: "20%", border: "none !important" }}
                column={2}
                style={{ width: "100%", marginBottom: "5px", border: "none" }}
              >
                <Descriptions.Item label={t(keys.ACTION_NUMBER)}>
                  {currentAction.actionNumber}
                </Descriptions.Item>
                <Descriptions.Item label={t(keys.ACTION_REFERENCE)}>
                  {currentAction.accessDbId}
                </Descriptions.Item>
                <Descriptions.Item label={t(keys.AUDIT)}>
                  {currentAction.audit} {currentAction.title}
                </Descriptions.Item>
                <Descriptions.Item label={t(keys.OBSERVATIONTYPE)}>
                  {t(`${currentAction.observationType.toUpperCase()}`)}
                </Descriptions.Item>
                <Descriptions.Item label={t(keys.RISK)}>
                  {t(`${currentAction.criticalityDegree}`)}
                </Descriptions.Item>
                <Descriptions.Item label={t(keys.EXPIRATION_DATE)}>
                  {FormatDateString(currentAction.actionExpiryDate)}
                </Descriptions.Item>
                <Descriptions.Item label={t(keys.OWNER)}>
                  {currentAction.businessUnitLeader.fullName}
                </Descriptions.Item>
                <Descriptions.Item label={t(keys.STATUS)}>
                  {t(`STATUS_${currentAction.status}`)}
                </Descriptions.Item>

                {!!currentAction.actionDelegates?.[0]?.delegatedUser && (
                  <Descriptions.Item label={t(keys.DELEGATE)}>
                    {currentAction.actionDelegates?.[0]?.delegatedUserFullName}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }} gutter={18}>
            <Col span={8}>
              <Card
                bodyStyle={cardStyle}
                headStyle={headStyle}
                title={t(keys.CRITICITY_CARD)}
              >
                <div style={{ whiteSpace: "pre-line" }}>
                  {currentAction.criticalityTitle}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                bodyStyle={cardStyle}
                headStyle={headStyle}
                title={t(keys.OBSERVATION)}
              >
                <div style={{ whiteSpace: "pre-line" }}>
                  {currentAction.observation}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                bodyStyle={cardStyle}
                headStyle={headStyle}
                title={t(keys.CORRECTIVE_ACTION)}
                bordered={false}
              >
                <div style={{ whiteSpace: "pre-line" }}>
                  {currentAction.plannedAction}
                </div>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }} gutter={18}>
            <Col span={8}>
              <Card
                bodyStyle={cardStyle}
                headStyle={headStyle}
                title={t(keys.MANAGEMENT_COMMENT)}
                bordered={false}
              >
                <div style={{ whiteSpace: "pre-line" }}>
                  {currentAction.managementComment}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                bodyStyle={cardStyle}
                headStyle={headStyle}
                title={t(keys.FINDINGS)}
              >
                <div style={{ whiteSpace: "pre-line" }}>
                  {currentAction.findings}
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                bodyStyle={cardStyle}
                headStyle={headStyle}
                title={t(keys.NOTE)}
                bordered={false}
              >
                <div style={{ whiteSpace: "pre-line" }}>
                  {currentAction.notes}
                </div>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginBottom: "10px" }} gutter={18}>
            <Col span={24}>
              <Card
                bodyStyle={cardStyle}
                headStyle={{ ...headStyle, width: "30%" }}
                title={t(keys.HISTORYLOG)}
                bordered={false}
              >
                <RequestsTable filters={requestFilters} pagesize={3} />
              </Card>
            </Col>
          </Row>
          {(isOwner || isDelegate) && (
              <>
                <Space size="large" direction="horizontal">
                  <Button
                    type="primary"
                    icon={<ClockCircleOutlined />}
                    disabled={!requestsAvailable}
                    onClick={() => {
                      setIsShowingRequestForm(true);
                      setIsShowingDetail(false);
                      setRequestType(RequestType.delay);
                      props.setModalWidth(50);
                    }}
                  >
                    {t(keys.DELAY_REQUEST)}
                  </Button>

                  <Button
                    type="primary"
                    icon={<IssuesCloseOutlined />}
                    disabled={!requestsAvailable}
                    onClick={() => {
                      setIsShowingRequestForm(true);
                      setIsShowingDetail(false);
                      setRequestType(RequestType.close);
                      props.setModalWidth(50);
                    }}
                  >
                    {t(keys.CLOSE_REQUEST)}
                  </Button>

                  {!isDelegate && !actionHasBeenDelegated && (
                    <Button
                      type="primary"
                      icon={<UserAddOutlined />}
                      disabled={!requestsAvailable}
                      onClick={() => {
                        setIsShowingRequestForm(true);
                        setIsShowingDetail(false);
                        setRequestType(RequestType.delegate);
                        props.setModalWidth(50);
                      }}
                    >
                      {t(keys.DELEGATE_ACTION)}
                    </Button>
                  )}
                  {!isDelegate && actionHasBeenDelegated && (
                    <Button
                      icon={<UserDeleteOutlined />}
                      type="primary"
                      disabled={!requestsAvailable}
                      onClick={() => {
                        setIsShowingRequestForm(true);
                        setIsShowingDetail(false);
                        setRequestType(RequestType.voidDelegate);
                        props.setModalWidth(50);
                      }}
                    >
                      {t(keys.DELEGATE_ACTION_UNDO)}
                    </Button>
                  )}
                </Space>
              </>
            )}
          <Button
            type="primary"
            style={{
              position: "absolute",
              right: "5px",
              top: "50%",
              width: "40px",
              height: "40px",
            }}
            icon={
              <ArrowRightOutlined
                style={{ fontSize: "26px" }}
                width="25px"
                height="25px"
              />
            }
            disabled={isLastElement}
            onClick={() => {
              setCurrentActionId(actions[currIndex + 1].id);
              props.setCurrentActionId(actions[currIndex + 1].id);
            }}
          ></Button>
          <Button
            style={{
              position: "absolute",
              left: "5px",
              top: "50%",
              width: "40px",
              height: "40px",
            }}
            type="primary"
            icon={
              <ArrowLeftOutlined
                style={{ fontSize: "26px" }}
                width="25px"
                height="25px"
              />
            }
            disabled={isFirstElement}
            onClick={() => {
              setCurrentActionId(actions[currIndex - 1].id);
              props.setCurrentActionId(actions[currIndex - 1].id);
            }}
          ></Button>
        </>
      )}
    </div>
  );
}

export default ActionDetail;
