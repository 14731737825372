import { AlertMessageDto } from "../dtos/AlertMessageDto";
import { patchDataAsync, putDataAsync } from "../functions/apiMethods";
import apiPaths from "../resources/api/apiPaths";

export async function upsertAdminAlert(alertMessageDto: AlertMessageDto) {
  if (!!alertMessageDto.id) {
    var result = await patchDataAsync<AlertMessageDto>({
      apiPath: apiPaths.updateAlertMessage.path,
      body: alertMessageDto,
    });
    return;
  }
  var result = await putDataAsync<AlertMessageDto>({
    apiPath: apiPaths.createAlertMessage.path,
    body: alertMessageDto,
  });
  return;
}
