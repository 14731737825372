import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import english from "./en.json";
import italian from "./it.json";

const resources = {
  en: { translation: english },
  it: { translation: italian },
};
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === "production" ? false : true,
    resources,
    interpolation: { escapeValue: false },
    react: { useSuspense: true },
    fallbackLng: "en",
    // detection: detectionOptions // for default options see https://github.com/i18next/i18next-browser-languageDetector/blob/9efebe6ca0271c3797bc09b84babf1ba2d9b4dbb/src/index.js#L11
  });

export default i18n;
