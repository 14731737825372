import { useTranslation } from "react-i18next";
import { keys } from "../../../../resources/translations/keys";

interface IGenericErrorMessageProps {}

function GenericErrorMessage(props: IGenericErrorMessageProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <div className="erg-letter-spacing-1">{t(keys.ERROR_GENERIC)}</div>
    </>
  );
}

export default GenericErrorMessage;
