import IApiPath from "../../interfaces/IApiPath";
import { ApiPathKey } from "../../types";

const apiPaths: { [key in ApiPathKey]: IApiPath } = {
  getCurrentUser: {
    key: "getCurrentUser",
    path: "/api/user/current",
    params: [],
  },
  auditActions: {
    key: "auditActions",
    path: "/api/Action/FilteredActions",
    params: [],
  },
  getAlertMessages: {
    key: "getAlertMessages",
    path: "/api/AlertMessage/Get",
    params: [],
  },
  createAuditLog: {
    key: "createAuditLog",
    path: "/api/AuditLog/Create",
    params: [],
  },
  createAuditLogDelete: {
    key: "createAuditLogDelete",
    path: "/api/AuditLogDelete/Create",
    params: [],
  },
  getAuditLogs: {
    key: "getAuditLogs",
    path: "/api/AuditLog/Get",
    params: [],
  },
  getAuditLogsDelete: {
    key: "getAuditLogsDelete",
    path: "/api/AuditLogDelete/Get",
    params: [],
  },
  getBusinessUnits: {
    key: "getBusinessUnits",
    path: "/api/Action/BusinessUnits",
    params: [],
  },
  getBusinessUnitsAdmin: {
    key: "getBusinessUnitsAdmin",
    path: "/api/Action/BusinessUnitsAdmin",
    params: [],
  },
  downloadAuditActions: {
    key: "auditActions",
    path: "/api/Action/Export",
    params: [],
  },
  createAlertMessage: {
    key: "createAlertMessage",
    path: "/api/AlertMessage/Create",
    params: [],
  },
  updateAlertMessage: {
    key: "updateAlertMessage",
    path: "/api/AlertMessage/Update",
    params: [],
  },
  deleteAlertMessage: {
    key: "deleteAlertMessage",
    path: "/api/AlertMessage/Delete",
    params: [],
  },
  deleteAuditAction: {
    key: "deleteAuditAction",
    path: "/api/Action/Delete",
    params: [],
  },
  getAuditedUsers: {
    key: "getCurrentUser",
    path: "/api/account/AuditedUsers",
    params: [],
  },
  downloadAccessLogs: {
    key: "downloadAccessLogs",
    path: "/api/AuditLog/ExportAccessLogs",
    params: [],
  },
  downloadActionsDeletedLogs: {
    key: "downloadActionsDeletedLogs",
    path: "/api/AuditLogDelete/ExportActionsDeletedLogs",
    params: [],
  },
  createRequest: {
    key: "createRequest",
    path: "/api/Action/CreateRequest",
    params: [],
  },
  getActionRequests: {
    key: "getActionRequests",
    path: "/api/Action/ActionRequests",
    params: [],
  },
  getReports: {
    key: "getReports",
    path: "/api/Account/OrgUsers",
    params: [],
  },
  createAction: {
    key: "createAction",
    path: "/api/Action/CreateAction",
    params: [],
  },
  GetAllUsers: {
    key: "GetAllUsers",
    path: "/api/Account/GetAllUsers",
    params: [],
  },

  upsertBusinessUnit: {
    key: "upsertBusinessUnit",
    path: "/api/Action/upsertBusinessUnit",
    params: [],
  },
  checkActionAccessDbExist: {
    key: "checkActionAccessDbExist",
    path: "/api/Action/CheckActionAccessDbExist",
    params: [],
  },
};

export default apiPaths;
