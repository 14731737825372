import { CSSProperties } from "react";
import { MessageNotificationType } from "../../../../types";

interface IMessageContentProps {
  message: string;
  type: MessageNotificationType;
}

function MessageContent(props: IMessageContentProps): JSX.Element {
  const messageStyle: CSSProperties = {
    padding: "0.5rem 1rem",
    borderRadius: 5,
    color: "white",
  };

  if (props.type === "error") messageStyle.backgroundColor = "#DB144F";
  else if (props.type === "warning") messageStyle.backgroundColor = "#F69A19";
  else if (props.type === "success") messageStyle.backgroundColor = "#31855B";
  else if (props.type === "info") messageStyle.backgroundColor = "#00749E";

  return <div style={messageStyle}>{props.message}</div>;
}

export default MessageContent;
