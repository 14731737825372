import {
  SaveOutlined,
  // DeleteFilled,
  // DeleteOutlined,
  // EditOutlined,
  UndoOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AuditActionDto } from "../../../dtos/AuditActionDto";
import { BusinessUnitLeaderDto } from "../../../dtos/BusinessUnitLeaderDto";
import { NewBusinessUnitDto } from "../../../dtos/NewBusinessUnitDto";
import { UserDto } from "../../../dtos/UserDto";
import { ActionStatus } from "../../../enumerations/ActionStatus";
import { CriticalityDegree } from "../../../enumerations/CriticalityDegree";
import { ObservationType } from "../../../enumerations/ObservationType";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { checkActionAccessDbExist } from "../../../queries/checkActionAccessDbExist";
import { createAction } from "../../../queries/createAction";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import { upsertBusinessUnit } from "../../../queries/upsertBusinessUnit";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 15 },
};
const tailLayout = {
  wrapperCol: { offset: 1, span: 16 },
};
interface ActionCreationProps {
  handleCancel: () => void;
  handleOk: () => void;
}
function ActionCreation(props: ActionCreationProps): JSX.Element {
  const { t } = useTranslation();
  const [apiPathList, setApiPathList] = useState(apiPaths.auditActions);
  const [businessUnitLeaders, setBusinessUnitLeaders] =
    useState<BusinessUnitLeaderDto[]>();
  const [users, setUsers] = useState<UserDto[]>();
  const [accessDbError, setAccessDbError] = useState(false);
  const [showNewBusinessLeaderForm, setShowNewBusinessLeaderForm] =
    useState(false);
  const [form] = Form.useForm();
  const query = useQuery({
    queryKey: [apiPaths.getBusinessUnitsAdmin],
    queryFn: () => GetErgTableDataAsync(apiPaths.getBusinessUnitsAdmin),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: async (data: IErgDataTableResponse<BusinessUnitLeaderDto>) =>
      await parseAndSetData(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  const queryUsers = useQuery({
    queryKey: [apiPaths.GetAllUsers],
    queryFn: () => GetErgTableDataAsync(apiPaths.GetAllUsers),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: async (data: IErgDataTableResponse<UserDto>) =>
      await parseAndSetDataUsers(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  const parseAndSetData = async (
    data: IErgDataTableResponse<BusinessUnitLeaderDto>,
  ) => {
    setBusinessUnitLeaders(data.items);
  };
  const parseAndSetDataUsers = async (data: IErgDataTableResponse<UserDto>) => {
    setUsers(data.items);
  };
  const [fieldValues, setFieldsValues] = useState<any>();
  const businessUnitLeadersOptions = businessUnitLeaders?.map((b) => {
    return (
      <Select.Option value={b?.id}>
        {b?.fullName} ({b?.businessUnit?.description})
      </Select.Option>
    );
  });
  const usersOptions = users?.map((b) => {
    return (
      <Select.Option value={`${b?.principalName}|${b?.fullName}`}>
        {b?.fullName}
      </Select.Option>
    );
  });
  const onFinish = async (values: any) => {
    const newAction: AuditActionDto = {
      accessDbId: values.accessDbId,
      actionNumber: values.actionNumber,
      year: values.year,
      audit: values.audit,
      company: values.company,
      observationType: values.observationType,
      criticalityTitle: values.criticalityTitle,
      title: values.title,
      observation: values.observation,
      advice: values.advice,
      managementComment: values.managementComment,
      plannedAction: values.plannedAction,
      businessUnit: values.businessUnit,
      actionExpiryDate: values.actionExpiryDate,
      expiry: values.expiry,
      actionOutcome: "",
      iAManager: values.iAManager,
      iAManager2: values.iAManager2 ?? "",
      iAManager3: values.iAManager3 ?? "",
      ril_262: values.ril_262 ? "SI" : "NO",
      ril_231: values.ril_231 ? "SI" : "NO",
      notes: values.notes ?? "",
      findings: values.findings ?? "",
      criticalityDegree: values.criticalityDegree,
      status: values.status,
      businessUnitLeader: {
        id: values.businessUnitLeaderId,
      },
    };
    await createAction(newAction);
    props.handleOk();
  };
  const onFinishNewUser = async (values: any) => {
    const newBu: NewBusinessUnitDto = {
      owner: values.user,
    };
    const buId = await upsertBusinessUnit(newBu);

    setShowNewBusinessLeaderForm(false);
    query.refetch();
    form.setFieldsValue({ ...fieldValues, businessUnitLeaderId: buId });
  };
  const checkAccessDb = async (e: any) => {
    if (!e.target.value || e.target.value === "") {
      return;
    }
    const checkResult = await checkActionAccessDbExist(e.target.value);
    setAccessDbError(checkResult);
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string; children: string },
  ) => {
    return (option?.children ?? "").toLowerCase().includes(input.toLowerCase());
  };
  return (
    <>
      <>
        {!showNewBusinessLeaderForm && (
          <Form
            {...layout}
            onFinish={onFinish}
            name="control-ref"
            size="middle"
            style={{ marginTop: "10" }}
            initialValues={{ ril_262: false, ril_231: false }}
            form={form}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  {...(accessDbError && {
                    help: t(keys.ID_ALREADY_PRESENT),
                    validateStatus: "error",
                  })}
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="accessDbId"
                  label={t(keys.ACCESSDBID)}
                >
                  <Input type="number" onChange={checkAccessDb} />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="actionNumber"
                  label={t(keys.ACTION_NUMBER)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="year"
                  label={t(keys.YEAR)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="audit"
                  label={t(keys.AUDIT)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="company"
                  label={t(keys.COMPANY)}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="observationType"
                  label={t(keys.OBSERVATIONTYPE)}
                >
                  <Select allowClear>
                    <Select.Option value={ObservationType.Criticity}>
                      {t(keys.CRITICITY)}
                    </Select.Option>
                    <Select.Option value={ObservationType.ImprovementArea}>
                      {t(keys.IMPROVEMENTAREA)}
                    </Select.Option>

                    <Select.Option value={ObservationType.Attention}>
                      {t(keys.ATTENTION_THEME)}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="criticalityTitle"
                  label={t(keys.CRITICALITYTITLE)}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="title"
                  label={t(keys.TITLE)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="observation"
                  label={t(keys.OBSERVATION)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="advice"
                  label={t(keys.ADVICE)}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="managementComment"
                  label={t(keys.MANAGEMENTCOMMENT)}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="plannedAction"
                  label={t(keys.PLANNEDACTION)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="businessUnit"
                  label={t(keys.BU_COMPANY_AREA)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: "center" }}>
                  <Form.Item
                    rules={[
                      { required: true, message: t(keys.MANDATORY_FIELD) },
                    ]}
                    style={{ marginBottom: "0" }}
                    name="businessUnitLeaderId"
                    label={t(keys.OWNER)}
                  >
                    <Select showSearch filterOption={filterOption} allowClear>
                      {businessUnitLeadersOptions}
                    </Select>
                  </Form.Item>
                  <Button
                    style={{
                      marginBottom: "10px",
                      marginTop: "2px",
                      marginLeft: "5px",
                    }}
                    type="primary"
                    icon={<UserAddOutlined />}
                    onClick={() => {
                      setFieldsValues(form.getFieldsValue());
                      setShowNewBusinessLeaderForm(true);
                    }}
                  >
                    {t(keys.ADD_OWNER)}
                  </Button>
                </div>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="actionExpiryDate"
                  label={t(keys.EXPIRATION_DATE)}
                >
                  <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="expiry"
                  label={t(keys.EXPIRY)}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="iAManager"
                  label={t(keys.IAMANAGER)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ textAlign: "center" }}
                  name="iAManager2"
                  label={t(keys.IAMANAGER2)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ textAlign: "center" }}
                  name="iAManager3"
                  label={t(keys.IAMANAGER3)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="ril_262"
                  valuePropName="checked"
                  label={t(keys.RIL626)}
                >
                  <Checkbox defaultChecked={false} />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="ril_231"
                  valuePropName="checked"
                  label={t(keys.RIL231)}
                >
                  <Checkbox defaultChecked={false} />
                </Form.Item>
                <Form.Item
                  style={{ textAlign: "center" }}
                  name="notes"
                  label={t(keys.NOTE)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ textAlign: "center" }}
                  name="findings"
                  label={t(keys.FINDINGS)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="criticalityDegree"
                  label={t(keys.RISK)}
                >
                  <Select allowClear>
                    <Select.Option value={CriticalityDegree.GREEN}>
                      {t(keys.GREEN)}
                    </Select.Option>
                    <Select.Option value={CriticalityDegree.YELLOW}>
                      {t(keys.YELLOW)}
                    </Select.Option>
                    <Select.Option value={CriticalityDegree.RED}>
                      {t(keys.RED)}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                  style={{ textAlign: "center" }}
                  name="status"
                  label={t(keys.STATUS)}
                >
                  <Select allowClear>
                    <Select.Option value={ActionStatus.open}>
                      {t(keys.STATUS_OPEN)}
                    </Select.Option>
                    <Select.Option value={ActionStatus.closed}>
                      {t(keys.STATUS_CLOSED)}
                    </Select.Option>

                    <Select.Option value={ActionStatus.NA}>
                      {t(keys.STATUS_NA)}
                    </Select.Option>

                    <Select.Option value={ActionStatus.notImplemented}>
                      {t(keys.STATUS_NOTIMPLEMENTED)}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item {...tailLayout}>
              <Space>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  htmlType="submit"
                >
                  {t(keys.SAVE)}
                </Button>
                <Button
                  icon={<UndoOutlined />}
                  onClick={props.handleCancel}
                  htmlType="button"
                >
                  {t(keys.UNDO)}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
        {showNewBusinessLeaderForm && (
          <div>
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 10 }}
              onFinish={onFinishNewUser}
              name="control-ref"
              size="middle"
              style={{ marginTop: "10" }}
            >
              <Form.Item
                rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
                name="user"
                label={t(keys.USER)}
              >
                <Select showSearch allowClear>
                  {usersOptions}
                </Select>
              </Form.Item>
              <Space>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  htmlType="submit"
                >
                  {t(keys.SAVE)}
                </Button>
                <Button
                  icon={<UndoOutlined />}
                  onClick={() => {
                    setShowNewBusinessLeaderForm(false);
                  }}
                  htmlType="button"
                >
                  {t(keys.UNDO)}
                </Button>
              </Space>
            </Form>
          </div>
        )}
      </>
    </>
  );
}

export default ActionCreation;
