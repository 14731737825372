import { AccountInfo } from "@azure/msal-browser";
import { useContext } from "react";
import AuthorizationContext from "../components/contexts/AuthorizationContext/AuthorizationContext";

function useAuthorization(): [
  (claimNames: string[]) => boolean,
  () => boolean,
  AccountInfo | undefined,
] {
  const authContext = useContext(AuthorizationContext)!;

  function isAuthenticated(): boolean {
    return !!authContext.user;
  }

  function isAuthorized(claimNames: string[]): boolean {
    let canAccessResource: boolean = false;

    if (claimNames.length === 0)
      throw new Error("At least on item in 'claimNames' must be specified.");

    let user: AccountInfo | undefined = authContext.user;
    if (user && !!user.idTokenClaims?.groups) {
      let userClaims: string[] = user.idTokenClaims.groups as string[];
      if (!!userClaims) {
        canAccessResource = claimNames.some((_) =>
          userClaims!.some((uc) => uc === _),
        );
      }
    }

    return canAccessResource;
  }

  return [isAuthorized, isAuthenticated, authContext.user];
}

export default useAuthorization;
