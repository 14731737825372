import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionRequestDto } from "../../../dtos/ActionRequestDto";
import { AuditRequestsFilterDto } from "../../../dtos/AuditRequestsFilterDto";
import {
  FormatDateString,
  getQueryParamFromObject,
} from "../../../functions/utils";
import IErgColumnType from "../../../interfaces/IErgDataTableColumnType";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import ErgDataTable from "../../data/ErgDataTable/ErgDataTable";

interface RequestsTableProps {
  filters: AuditRequestsFilterDto;
  pagesize?: number;
}

function RequestsTable(props: RequestsTableProps): JSX.Element {
  const params = getQueryParamFromObject(props.filters);
  const { t } = useTranslation();
  const [apiPath, setApiPath] = useState({
    ...apiPaths.getActionRequests,
    params: params,
  });
  const columns: IErgColumnType<ActionRequestDto>[] = [
    {
      title: t(keys.DATE),
      dataPropertyName: "createdOn",
      isSortable: false,
      render: (date: string) => <>{FormatDateString(date, true)}</>,
    },
    {
      title: t(keys.NOTE),
      dataPropertyName: "notes",
      isSortable: false,
    },
  ];
  useEffect(() => {
    const params = getQueryParamFromObject(props.filters);
    setApiPath({ ...apiPaths.getActionRequests, params: params });
  }, [props.filters]);
  return (
    <>
      <div>
        <ErgDataTable<ActionRequestDto>
          apiPath={apiPath}
          apiTableQueryMethod={GetErgTableDataAsync}
          key={"id"}
          columns={columns}
          pagesize={props.pagesize}
        />
      </div>
    </>
  );
}

export default RequestsTable;
