import { useEffect, useState } from "react";
import { AuditLogDto } from "../../../dtos/AuditLogDto";
import { AuditLogFiltersDto } from "../../../dtos/AuditLogFiltersDto";
import { getQueryParamFromObject } from "../../../functions/utils";
import IErgColumnType from "../../../interfaces/IErgDataTableColumnType";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import ErgDataTable from "../../data/ErgDataTable/ErgDataTable";

interface AuditLogTableProps {
  columns: IErgColumnType<AuditLogDto>[];
  filters: AuditLogFiltersDto;
  pagesize?: number;
}

function AuditLogTable(props: AuditLogTableProps): JSX.Element {
  const params = getQueryParamFromObject(props.filters);
  const [apiPath, setApiPath] = useState({
    ...apiPaths.getAuditLogs,
    params: params,
  });
  useEffect(() => {
    const params = getQueryParamFromObject(props.filters);
    setApiPath({ ...apiPaths.getAuditLogs, params: params });
  }, [props.filters]);
  return (
    <>
      <div>
        <ErgDataTable<AuditLogDto>
          apiPath={apiPath}
          apiTableQueryMethod={GetErgTableDataAsync}
          key={"id"}
          columns={props.columns}
          pagesize={props.pagesize}
        />
      </div>
    </>
  );
}

export default AuditLogTable;
