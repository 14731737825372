import { Form, Select } from "antd";
import Text from "antd/es/typography/Text";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { UserDto } from "../../../dtos/UserDto";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import { AuditActionDto } from "../../../dtos/AuditActionDto";

interface DelegateRequestFormProps {
  currentActionId: number;
  currentAction:AuditActionDto;
  goBack: (values: any) => void;
}

function DelegateRequestForm(props: DelegateRequestFormProps): JSX.Element {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserDto[]>();
  const query = useQuery({
    queryKey: [apiPaths.getReports],
    queryFn: () => GetErgTableDataAsync(apiPaths.getReports),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: async (data: IErgDataTableResponse<UserDto>) =>
      await parseAndSetData(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
  const parseAndSetData = async (data: IErgDataTableResponse<UserDto>) => {
    setUsers(data.items);
  };
  const userOptions = users?.filter((user)=>user.principalName !== props.currentAction.businessUnitLeader.principalName).map((b) => {
    return (
      <Select.Option
        rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
        value={b?.principalName}
      >
        {b?.fullName}
      </Select.Option>
    );
  });
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <Text>{t(keys.SELECT_USER_TO_DELEGATE)}</Text>
      </div>
      <Form.Item
        rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
        label={t(keys.USER)}
        name="user"
      >
        <Select allowClear>{userOptions}</Select>
      </Form.Item>
    </>
  );
}

export default DelegateRequestForm;
