import {
  HttpHeaderValueKey,
  HttpHeaderValueValue,
  HttpMethod,
} from "../../types";

const httpUtils: {
  bodiedHttpMethods: HttpMethod[];
  headers: {
    values: Record<HttpHeaderValueKey, HttpHeaderValueValue>;
  };
} = {
  bodiedHttpMethods: ["POST", "PUT", "PATCH"],
  headers: {
    values: {
      bearer: (token: string) => `Bearer ${token}`,
    },
  },
};

export default httpUtils;
