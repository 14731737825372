const enum SpinnerSize {
  Smallest = "0.5rem",
  Smaller = "0.75rem",
  Small = "1rem",
  Default = "1.5rem",
  Large = "2rem",
  Larger = "2.75rem",
  Largest = "3.5rem",
  Enormous = "5rem",
}

export default SpinnerSize;
