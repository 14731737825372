import ActionsDeletedTable from "../../data/ActionsDeletedTable/ActionsDeletedTable";

function DeleteLogPage(): JSX.Element {
  return (
    <>
      <ActionsDeletedTable showFilters pagesize={10} />
    </>
  );
}

export default DeleteLogPage;
