import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import MsalSpinnerOverlay from "./components/authentication/MsalSpinnerOverlay/MsalSpinnerOverlay";
import { msalConfig } from "./resources/auth/authConfig";
import "./resources/translations/i18n";
// import { ErrorBoundary } from "react-error-boundary";
// import UnhandledErrorFallback from "./components/utils/errorFallback/UnhandledErrorFallback";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .initialize()
  .then((result) => {
    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });
  })
  .catch((e) => {
    console.log(e);
  });

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <App />
      {/* <ErrorBoundary FallbackComponent={UnhandledErrorFallback}></ErrorBoundary> */}
      <MsalSpinnerOverlay />
    </MsalProvider>
  </BrowserRouter>,
);
