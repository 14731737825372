import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditLogDeleteDto } from "../../../dtos/AuditLogDeleteDto";
import { AuditLogDeleteFiltersDto } from "../../../dtos/AuditLogDeleteFiltersDto";
import {
  FormatDateString,
  getQueryParamFromObject,
} from "../../../functions/utils";
import IErgColumnType from "../../../interfaces/IErgDataTableColumnType";
import { DownloadFileQuery } from "../../../queries/downloadFileQuery";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import AuditLogDeleteTable from "../AuditLogDeleteTable/AuditLogDeleteTable";
import ActionsDeletedTableFilters from "./ActionsDeletedTableFilters";
interface ActionsDeletedTableProps {
  pagesize?: number;
  showFilters?: boolean;
}

function ActionsDeletedTable(props: ActionsDeletedTableProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<AuditLogDeleteFiltersDto>({
  });
  const [apiPathDownload, setApiPathDownload] = useState({
    ...apiPaths.downloadActionsDeletedLogs,
    downloadFileName: `${t(
      keys.ACTIONS_DELETED_LOG_DOWNLOAD_FILENAME,
    )}_${new Date().toISOString()}.xlsx`,
  });
  const columns: IErgColumnType<AuditLogDeleteDto>[] = [
    {
      title: t(keys.USER),
      dataPropertyName: "notes",
      isSortable: false,
    },
    {
      title: t(keys.ACTION_ID),
      dataPropertyName: "accessDbId",
      isSortable: false,
    },
    {
      title: t(keys.DATE),
      dataPropertyName: "createdOn",
      render: (text) => <>{FormatDateString(text, true)}</>,
      isSortable: false,
      defaultSortOrder: "descend",
    },
  ];

  const onFiltersSet = (values: any) => {
    const filters: AuditLogDeleteFiltersDto = {
      notes: values.user,
      accessLogFrom: values.accessLogDate?.[0].toISOString(),
      accessLogTo: values.accessLogDate?.[1].toISOString(),
    };
    const newApiPathDownload = { ...apiPathDownload };

    const params = getQueryParamFromObject(filters);
    newApiPathDownload.params = params;

    setApiPathDownload(newApiPathDownload);
    setFilters(filters);
  };

  return (
    <>
      {props.showFilters && (
        <ActionsDeletedTableFilters
          onFinish={onFiltersSet}
          downloadButton={
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={() => DownloadFileQuery(apiPathDownload, i18n.language)}
            >
              {t(keys.DOWNLOAD)}
            </Button>
          }
        />
      )}
      <AuditLogDeleteTable
        pagesize={props.pagesize}
        columns={columns}
        filters={filters}
      />
    </>
  );
}

export default ActionsDeletedTable;
