import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getRouteKeys } from "../../../../functions/navigation";
import IBaseComponentProps from "../../../../interfaces/IBaseComponentProps";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";
import ErgLogo from "../../../others/ErgLogo/ErgLogo";
import ErgButton from "../../ErgButton/ErgButton";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import "./ErgHeader.scss";

interface IErgHeaderProps extends IBaseComponentProps {}

function ErgHeader(props: IErgHeaderProps): JSX.Element {
  const [pageTitle, setPageTitle] = useState<string | undefined>();
  const layoutContext = useContext(LayoutContext)!;
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let routeKeys = getRouteKeys(location.pathname);
    let routePageTitleKey = routeKeys[routeKeys.length - 1];
    let routePageTitle = t(routePageTitleKey);
    setPageTitle(routePageTitle);
  }, [location.pathname, t]);
  return (
    <Header className="erg-header">
      <div className="erg-h-100 erg-dflex">
        <ErgButton
          className="erg-header-menu-button erg-align-self-center erg-p-0"
          type={"green"}
          icon={
            layoutContext.navMenu.isCollapsed ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined />
            )
          }
          onClick={() =>
            layoutContext.navMenu.setIsCollapsed(
              !layoutContext.navMenu.isCollapsed,
            )
          }
        />
        <LanguageSelector />
        {/* <UiAuth claimNames={[claims.entities.notification.read]}>
            <LillyNotificationDisplay />
          </UiAuth> */}
        {/* <LillyUserDisplay /> */}
        <div
          className={`erg-title-info erg-flex-1 erg-text-center erg-font-large erg-text-capitalized`}
        >
          {pageTitle}
        </div>
        <div
          className={`erg-title-info   erg-text-capitalized`}
          style={{ fontSize: 17, textAlign: "end" }}
        >
          APMWeb - Action Plan Management
        </div>
        <ErgLogo type={"dark"} className="erg-mw-150 erg-p-2" />
      </div>
    </Header>
  );
}

export default ErgHeader;
