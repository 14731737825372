import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

import { ReactComponent as ENFlag } from "../../../../style/en-flag.svg";
import { ReactComponent as ITFlag } from "../../../../style/it-flag.svg";
import { colors } from "../../../../style/variables";

function LanguageSelector(): JSX.Element {
  const { t, i18n } = useTranslation();
  const isLanguageSelected = (language: string) => {
    return i18n.language === language;
  };
  return (
    <div style={{ marginLeft: "15px", marginTop: "2px" }}>
      <Space direction="horizontal">
        <Button
          style={
            isLanguageSelected("en")
              ? {
                  outlineColor: colors.erggreen,
                  outlineStyle: "solid",
                  outlineWidth: "3px",
                  background: "none",
                  border: "0",
                }
              : { background: "none", border: "0" }
          }
          icon={<ENFlag style={{ width: "100%", height: "100%" }} />}
          onClick={() => {
            i18n.changeLanguage("en");
          }}
        ></Button>
        <Button
          style={
            isLanguageSelected("it")
              ? {
                  outlineColor: colors.erggreen,
                  outlineStyle: "solid",
                  outlineWidth: "3px",
                  background: "none",
                  border: "0",
                }
              : { background: "none", border: "0" }
          }
          icon={
            <ITFlag
              style={{
                width: "100%",
                height: "100%",
                verticalAlign: "baseline",
              }}
            />
          }
          onClick={() => {
            i18n.changeLanguage("it");
          }}
        ></Button>
      </Space>
    </div>
  );
}

export default LanguageSelector;
