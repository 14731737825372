import { Configuration, RedirectRequest } from "@azure/msal-browser";
import config from "../../appSettings.json";

export const msalConfig: Configuration = {
  auth: {
    clientId: config.azureAd.clientId,
    authority: `${config.azureAd.instance}${config.azureAd.tenantId}`,
    redirectUri: config.azureAd.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: config.azureAd.scopes,
};
