import { createContext, Dispatch, SetStateAction } from "react";
import IErgMessageNotifier from "../../../interfaces/IErgMessageNotifier";

export interface ILayoutContext {
  navMenu: {
    isCollapsed: boolean;
    setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  };
  messageNotifier: IErgMessageNotifier;
}

var LayoutContext = createContext<ILayoutContext | null>(null);

export default LayoutContext;
