import { SendRequestDto } from "../dtos/SendRequestDto";
import { putDataAsync } from "../functions/apiMethods";
import apiPaths from "../resources/api/apiPaths";

export async function sendRequest(sendRequestDto: SendRequestDto) {
  var result = await putDataAsync<SendRequestDto>({
    apiPath: apiPaths.createRequest.path,
    body: sendRequestDto,
  });
}
