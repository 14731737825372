import { Result, Table, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IApiPath from "../../../interfaces/IApiPath";
import IErgDataTableColumnSorter from "../../../interfaces/IErgDataTableColumnSorter";
import IErgDataTableColumnType from "../../../interfaces/IErgDataTableColumnType";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { keys } from "../../../resources/translations/keys";
import ErgButton from "../../ui/ErgButton/ErgButton";
import "./ErgDataTable.scss";
// import ILillyDataTableProps from "../interfaces/ILillyDefaultDataTableProps";
// import { useQuery } from "@tanstack/react-query";
// import { ITableResponse } from "../../../interfaces/ITableResponse";
import { StopOutlined } from "@ant-design/icons";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { useQuery } from "react-query";
// import { useTranslation } from "react-i18next";
// import LillyButton from "../../ui/buttons/LillyButton/LillyButton";
// import { useEffect, useState } from "react";
// import { capitalize, titleize } from "../../../functions/utils";
// import { keys } from "../../../resources/i18n/keys";
// import { getSorterForProperty } from "../functions/utils";

interface IErgDataTableProps<TData> {
  columns: IErgDataTableColumnType<TData>[];
  rowKey?: string;
  apiPath: IApiPath;
  apiTableQueryMethod: (
    apiPath: IApiPath,
    currentPage: number,
    pageSize: number,
    sorter?: IErgDataTableColumnSorter,
  ) => void;
  onRowEdit?: (row: TData) => void;
  onRowDelete?: (row: TData) => void;
  isEditEnabled?: boolean;
  isDeleteEnabled?: boolean;
  pagesize?: number;
  setCount?: (count: number) => void;
}

function ErgDataTable<TData extends object>(
  props: IErgDataTableProps<TData>,
): JSX.Element {
  let sortedColumnByDefault = props.columns.filter(
    (col) => col.isSortable !== false && col.defaultSortOrder,
  )[0];
  let defaultSorter: IErgDataTableColumnSorter | undefined =
    sortedColumnByDefault
      ? {
          dataPropertyName: sortedColumnByDefault.dataPropertyName,
          isDescending: sortedColumnByDefault.defaultSortOrder === "descend",
        }
      : undefined;

  const { t } = useTranslation();
  const [data, setData] = useState<TData[]>([]);
  const [columns, setColumns] = useState<IErgDataTableColumnType<TData>[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(props.pagesize ?? 10);
  const [totalItems, setTotalItems] = useState(0);
  const [sorter, setSorter] = useState<IErgDataTableColumnSorter | undefined>(
    defaultSorter,
  );
  const query = useQuery({
    queryKey: [props.apiPath.key, currentPage, pageSize, sorter],
    queryFn: () =>
      props.apiTableQueryMethod(props.apiPath, currentPage, pageSize, sorter),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: (data: IErgDataTableResponse<TData>) => parseAndSetData(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const parseAndSetData = (data: IErgDataTableResponse<TData>) => {
    setTotalItems(data.count);
    props.setCount?.(data.count);
    setData(data.items);
  };

  const onChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TData> | SorterResult<TData>[],
  ) => {
    let singleSorter = sorter as SorterResult<TData>;

    setCurrentPage(pagination.current ?? 1);
    setPageSize(pagination.pageSize ?? 10);

    if (!!singleSorter.column) {
      setSorter({
        dataPropertyName: singleSorter.column!.dataIndex!.toString(),
        isDescending: singleSorter.order === "descend",
      });
    }
  };

  // const onEditButtonClick = (record: TData) => {
  //   if (record && props.onRowEdit) {
  //     props.onRowEdit(record)!;
  //   }
  // };

  // AT INITIALIZATION
  useEffect(() => {
    const actionColumns: IErgDataTableColumnType<TData>[] = [];
    // if (props.isDeleteEnabled !== false && !!props.onRowDelete) {
    //   actionColumns.push({
    //     title: " ",
    //     key: "delete",
    //     dataIndex: "delete",
    //     sorter: false,
    //     showSorterTooltip: false,
    //     className: "lilly-button-column",
    //     render: (text, record) => (
    //       <>
    //         <Popconfirm
    //           title={titleize(t(keys.SPREADSHEET_ACTIONS_DELETE))}
    //           description={capitalize(t(keys.SPREADSHEET_ACTIONS_DELETE_INFO))}
    //           onConfirm={() => props.onRowDelete!(record)}
    //           okText={titleize(t(keys.SPREADSHEET_ACTIONS_YES))}
    //           cancelText={titleize(t(keys.SPREADSHEET_ACTIONS_NO))}
    //         >
    //           <LillyButton>
    //             <DeleteOutlined />
    //           </LillyButton>
    //         </Popconfirm>
    //       </>
    //     ),
    //     width: 40,
    //   });
    // }

    // if (props.isEditEnabled !== false && !!props.onRowEdit) {
    //   actionColumns.push({
    //     title: " ",
    //     key: "edit",
    //     dataIndex: "edit",
    //     sorter: false,
    //     showSorterTooltip: false,
    //     className: "lilly-button-column",
    //     render: (text, record) => (
    //       <>
    //         <LillyButton
    //           onClick={() => {
    //             onEditButtonClick(record);
    //           }}
    //         >
    //           <EditOutlined />
    //         </LillyButton>
    //       </>
    //     ),
    //     width: 40,
    //   });
    // }

    const dataColumns: IErgDataTableColumnType<TData>[] = props.columns.map(
      (col) => {
        let isSortable = col.isSortable ?? true;

        return {
          dataPropertyName: col.dataPropertyName,
          dataIndex: col.dataPropertyName,
          key: col.dataPropertyName,
          title: col.title,
          render: col.render,
          ellipsis: col.ellipsis,
          defaultSortOrder: isSortable ? col.defaultSortOrder : undefined,
          sorter: isSortable,
          showSorterTooltip: false,
        };
      },
    );
    const columns = [...actionColumns, ...dataColumns];
    setColumns(columns);
  }, []);
  useEffect(() => {
    //Refresh data if path changes
    query.refetch();
  }, [props.apiPath]);
  return (
    <div style={{ marginRight: 20, marginLeft: 20 }}>
      {!query.isError && (
        <Table
          className="lilly-datatable lilly-datatable-default"
          columns={columns}
          rowKey={props.rowKey}
          dataSource={data}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalItems,
          }}
          loading={query.isLoading || query.isRefetching}
          onChange={onChange}
        />
      )}
      {query.isError && (
        <Result
          icon={<StopOutlined />}
          title={t(keys.ERROR_GENERIC)}
          extra={
            <ErgButton onClick={() => query.refetch()} type={"dark-blue"}>
              {t(keys.REFRESH)}
            </ErgButton>
          }
        />
      )}
    </div>
  );
}

export default ErgDataTable;
