import { Button, Form, Space, Upload } from "antd";
import Text from "antd/es/typography/Text";
import { useTranslation } from "react-i18next";
import { keys } from "../../../resources/translations/keys";

import {
  SaveOutlined,
  UndoOutlined,
  // DeleteFilled,
  // DeleteOutlined,
  // EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { SendRequestDto } from "../../../dtos/SendRequestDto";
import { RequestType } from "../../../enumerations/RequestType";
import { sendRequest } from "../../../queries/sendRequest";
import DelegateRequestForm from "../DelegateRequestForm/DelegateRequestForm";
import { AuditActionDto } from "../../../dtos/AuditActionDto";
interface ActionRequestFormProps {
  requestType: RequestType;
  currentActionId: number;
  currentAction:AuditActionDto
  goBack: () => void;
}
function readFile(file: any) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = (e) => {
      file.base64 = e?.target?.result;
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file.originFileObj);
  });
}
function ActionRequestForm(props: ActionRequestFormProps): JSX.Element {
  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    let fileList = [...(values?.files?.fileList || [])];
    for (const file of fileList) {
      await readFile(file);
    }
    const filesB64 = fileList?.map((f) => {
      return { item1: f.name, item2: f.base64.split("base64,")[1] };
    });

    const payload: SendRequestDto = {
      notes: values.notes,
      requestTypeString: props.requestType,
      attachments: filesB64,
      actionId: props.currentActionId,
      delegatedUser: values.user,
    };
    sendRequest(payload);
    props.goBack();
  };
  return (
    <>
      <Form onFinish={onFinish}>
        {(props.requestType === RequestType.delay ||
          props.requestType === RequestType.close) && (
          <>
            <Form.Item
              name="notes"
              label={t(keys.NOTE)}
              style={{ textAlign: "center" }}
              rules={[{ required: true, message: t(keys.MANDATORY_FIELD) }]}
            >
              <TextArea style={{ minHeight: "100px" }} />
            </Form.Item>
            <Form.Item name="files" valuePropName="defaultFileList">
              <Upload
                listType={"text"}
                multiple={true}
                beforeUpload={() => {
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>
                  {t(keys.UPLOAD_FILES)}
                </Button>
              </Upload>
            </Form.Item>
          </>
        )}
        {props.requestType === RequestType.voidDelegate && (
          <div style={{ textAlign: "center" }}>
            <Text>{t(keys.CONFIRM_UNDO_DELEGATE)}</Text>
          </div>
        )}
        {props.requestType === RequestType.delegate && (
          <DelegateRequestForm
            goBack={props.goBack}
            currentActionId={props.currentActionId}
            currentAction={props.currentAction}
          />
        )}
        <Space direction="horizontal" size="middle">
          <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
            {t(keys.SEND)}
          </Button>
          <Button icon={<UndoOutlined />} onClick={props.goBack}>
            {t(keys.UNDO)}
          </Button>
        </Space>
      </Form>
    </>
  );
}

export default ActionRequestForm;
