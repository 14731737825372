import SpinnerSize from "../../../enumerations/SpinnerSize";
import SpinnerType from "../../../enumerations/SpinnerType";
import "./Spinner.scss";

interface ISpinnerProps {
  size?: SpinnerSize;
  type?: SpinnerType;
}

function Spinner(props: ISpinnerProps): JSX.Element {
  return (
    <div
      className={`erg-spinner erg-spinner-${props.type ?? SpinnerType.Default}`}
    >
      <svg
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ?? SpinnerSize.Default}
        height={props.size ?? SpinnerSize.Default}
      >
        <circle cx="50" cy="50" r="45" />
      </svg>
    </div>
  );
}

export default Spinner;
