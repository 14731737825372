import AdminAlertsTable from "../../data/AdminAlertsTable/AdminAlertsTable";

function AlertMessagePage(): JSX.Element {
  return (
    <>
      <AdminAlertsTable />
    </>
  );
}

export default AlertMessagePage;
