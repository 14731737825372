import { useEffect, useState } from "react";
import { AuditLogDeleteFiltersDto } from "../../../dtos/AuditLogDeleteFiltersDto";
import { getQueryParamFromObject } from "../../../functions/utils";
import IErgColumnType from "../../../interfaces/IErgDataTableColumnType";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import ErgDataTable from "../ErgDataTable/ErgDataTable";
import { AuditLogDeleteDto } from "../../../dtos/AuditLogDeleteDto";

interface AuditLogDeleteTableProps {
  columns: IErgColumnType<AuditLogDeleteDto>[];
  filters: AuditLogDeleteFiltersDto;
  pagesize?: number;
}

function AuditLogDeleteTable(props: AuditLogDeleteTableProps): JSX.Element {
  const params = getQueryParamFromObject(props.filters);
  const [apiPath, setApiPath] = useState({
    ...apiPaths.getAuditLogsDelete,
    params: params,
  });
  useEffect(() => {
    const params = getQueryParamFromObject(props.filters);
    setApiPath({ ...apiPaths.getAuditLogsDelete, params: params });
  }, [props.filters]);
  return (
    <>
      <div>
        <ErgDataTable<AuditLogDeleteDto>
          apiPath={apiPath}
          apiTableQueryMethod={GetErgTableDataAsync}
          key={"id"}
          columns={props.columns}
          pagesize={props.pagesize}
        />
      </div>
    </>
  );
}

export default AuditLogDeleteTable;
