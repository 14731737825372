import { Carousel, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AlertMessageDto } from "../../../dtos/AlertMessageDto";
import { AlertMessageFiltersDto } from "../../../dtos/AlertMessageFiltersDto";
import { getQueryParamFromObject } from "../../../functions/utils";
import IApiPath from "../../../interfaces/IApiPath";
import { IErgDataTableResponse } from "../../../interfaces/IErgDataTableResponse";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import apiPaths from "../../../resources/api/apiPaths";
import { colors } from "../../../style/variables";
const { Title } = Typography;
const contentStyle: React.CSSProperties = {
  color: "#fff",
  textAlign: "center",
  background: colors.ergdarkblue,
  paddingTop: 30,
  minHeight: 150,
  padding: "10px 10px 10px 10px",
};
function Counter(): JSX.Element {
  const { t } = useTranslation();
  const [alertsToDisplay, setAlertsToDisplay] = useState<AlertMessageDto[]>();
  const [apiPath, setApiPath] = useState<IApiPath>(apiPaths.getAlertMessages);
  useEffect(() => {
    const filters: AlertMessageFiltersDto = {
      expiryDateFrom: new Date().toISOString(),
    };
    const newApiPath = { ...apiPaths.getAlertMessages };
    const params = getQueryParamFromObject(filters);
    newApiPath.params = params;
    setApiPath(newApiPath);
  }, []);

  const query = useQuery({
    queryKey: [apiPath],
    queryFn: () => GetErgTableDataAsync(apiPath),
    enabled: true,
    cacheTime: Infinity,
    onSuccess: async (data: IErgDataTableResponse<AlertMessageDto>) =>
      await parseAndSetData(data),
    onError: (err: Error) => {
      console.log(`ERROR on table query: ${err}`);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const parseAndSetData = async (
    data: IErgDataTableResponse<AlertMessageDto>,
  ) => {
    setAlertsToDisplay(data.items);
  };

  const displayAlerts = alertsToDisplay?.map((a, index) => {
    return (
      <div key={index}>
        <div
          style={contentStyle}
          dangerouslySetInnerHTML={{ __html: a.message }}
        />
      </div>
    );
  });

  return (
    <>
      {(displayAlerts?.length ?? 0) > 0 && (
        <>
          <Carousel dotPosition="top" style={{ margin: "10px 20px 10px 20px", maxWidth: "500px" }}>
            {displayAlerts}
          </Carousel>
        </>
      )}
    </>
  );
}

export default Counter;
