import { useContext } from "react";
import { To } from "react-router";
import { NavLink } from "react-router-dom";
import IBaseComponentProps from "../../../../interfaces/IBaseComponentProps";
import LayoutContext from "../../../contexts/LayoutContext/LayoutContext";

interface IErgLayoutNavLinkProps extends IBaseComponentProps {
  to: To;
}

function ErgLayoutNavLink(props: IErgLayoutNavLinkProps): JSX.Element {
  const layoutContext = useContext(LayoutContext)!;

  return (
    <NavLink
      {...props}
      to={props.to}
      onClick={() => layoutContext.navMenu.setIsCollapsed(true)}
    >
      {props.children}
    </NavLink>
  );
}

export default ErgLayoutNavLink;
