import { AuditLogDeleteBaseDto } from "../dtos/AuditLogDeleteBaseDto";
import { putDataAsync } from "../functions/apiMethods";
import apiPaths from "../resources/api/apiPaths";

export async function CreateAuditLogDelete(AuditLogDeleteDto: AuditLogDeleteBaseDto) {
  var result = await putDataAsync<AuditLogDeleteBaseDto>({
    apiPath: apiPaths.createAuditLogDelete.path,
    body: AuditLogDeleteDto,
  });
}
