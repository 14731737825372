import { deleteDataAsync } from "../functions/apiMethods";
import { getQueryParamFromObject } from "../functions/utils";
import apiPaths from "../resources/api/apiPaths";

export async function deleteAction(auditActionId: number) {
  const params = getQueryParamFromObject({
    id: auditActionId,
  });
  var result = await deleteDataAsync({
    apiPath: apiPaths.deleteAuditAction.path,
    queryparams: params,
  });
  return;
}
