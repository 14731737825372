import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../resources/navigation/routes";
import { keys } from "../../../resources/translations/keys";
// import HttpErrorMessage from "./components/HttpErrorMessage";
// import StatusCode from "status-code-enum";
// import ErrorType from "../../enumerations/ErrorType";
// import { capitalize } from "../../functions/utils";
// import GenericErrorMessage from "./components/GenericErrorMessage";
import HttpStatusCode from "../../../enumerations/HttpStatusCode";
import { get } from "../../../functions/utils";
import { ErrorRouteParam, ErrorType } from "../../../types";
import GenericErrorMessage from "../../navigation/errorMessages/GenericErrorMessage/GenericErrorMessage";
import HttpErrorMessage from "../../navigation/errorMessages/HttpErrorMessage/HttpErrorMessage";
import ErgButton from "../../ui/ErgButton/ErgButton";

interface IErrorPageProps {
  children?: ReactNode;
}

function ErrorPage(props: IErrorPageProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [messageComponent, setMessageComponent] = useState(props.children);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let paramType = searchParams.get(get<ErrorRouteParam>("type"));
    let errorType: ErrorType | undefined = paramType as ErrorType;
    let paramCode: string | null = null;
    if (errorType === "http") paramCode = searchParams.get("code");

    if (errorType === "generic" || !paramCode) {
      setMessageComponent(<GenericErrorMessage />);
    } else if (errorType === "http" && paramCode) {
      let code: HttpStatusCode = +paramCode;
      setMessageComponent(<HttpErrorMessage code={code} />);
    }

    return;
  }, [props.children, searchParams.get("code")]);

  return (
    <div className="erg-h-100 erg-center-box">
      <div className="erg-text-center">
        <div>{messageComponent}</div>
        <ErgButton
          type={"light-blue"}
          onClick={() => navigate(routes.home.path)}
          className="erg-mt-3"
        >
          {t(keys.BACK_TO_HOME)}
        </ErgButton>
      </div>
    </div>
  );
}

export default ErrorPage;
