import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import SpinnerSize from "../../../enumerations/SpinnerSize";
import SpinnerType from "../../../enumerations/SpinnerType";
import Spinner from "../../ui/Spinner/Spinner";

function MsalSpinnerOverlay(): JSX.Element | null {
  const { inProgress } = useMsal();

  return inProgress === InteractionStatus.None ? null : (
    <div className={"erg-overlay-dark erg-center-box"}>
      <Spinner type={SpinnerType.Light} size={SpinnerSize.Enormous} />
    </div>
  );
}

export default MsalSpinnerOverlay;
