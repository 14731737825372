import routes, { RouteKey } from "../resources/navigation/routes";

export function getRouteKeys(locationPath: string): string[] {
  let routeKeys: string[] | undefined;
  Object.keys(routes).forEach((key) => {
    var route = routes[key as RouteKey];
    if (!!routeKeys) return;
    if (route.path === locationPath) routeKeys = route.keys;
  });

  return routeKeys ?? [];
}
