import { AccountInfo, EventType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthorizationContext from "./components/contexts/AuthorizationContext/AuthorizationContext";
import Routes from "./components/navigation/Routes/Routes";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import { AuditLogBaseDto } from "./dtos/AuditLogBaseDto";
import { LogType } from "./enumerations/LogType";
import { CreateAuditLog } from "./queries/createAuditLog";
import "./style/site.scss";
import { colors } from "./style/variables";
const queryClient = new QueryClient();

function App(): JSX.Element {
  const [user, setUser] = useState<AccountInfo>();
  const { instance } = useMsal();

  useEffect(() => {
    // This will be run on component mount
    const callbackId = instance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
        if (!result) {
          return;
        }
        if (!("account" in result)) {
          return;
        }
        const account = result.account;
        if (!account) {
          return;
        }
        if (!("username" in account)) {
          return;
        }
        const auditLog: AuditLogBaseDto = {
          logType: LogType.Login,
          notes: account.username,
        };
        setUser(account);
        CreateAuditLog(auditLog);
      }
    });
    const activeAccount = instance.getActiveAccount();
    if (!!activeAccount) {
      setUser(activeAccount);
    }

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Calibri",
            colorPrimary: colors.erggreen,
          },
        }}
      >
        <AuthenticatedTemplate>
          <AuthorizationContext.Provider value={{ user: user }}>
            <QueryClientProvider client={queryClient}>
              <Routes />
            </QueryClientProvider>
          </AuthorizationContext.Provider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage />
        </UnauthenticatedTemplate>
      </ConfigProvider>
    </>
  );
}

export default App;
