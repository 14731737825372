import { Space } from "antd";
import { useTranslation } from "react-i18next";
import config from "../../../appSettings.json";
import SignInButton from "../../authentication/SignInButton/SignInButton";
import ErgLogo from "../../others/ErgLogo/ErgLogo";
import "./LoginPage.scss";

function LoginPage(): JSX.Element {
  const { t } = useTranslation();
  const titleString =
    config.env === "test"
      ? `APMWeb - Action Plan Management - Test`
      : "APMWeb - Action Plan Management";
  return (
    <div className="erg-login-page erg-h-100 erg-center-box">
      <div className="erg-login-card erg-w-50 erg-mw-500">
        <ErgLogo className="erg-p-4" type={"dark"} />
        <div className="erg-center-box erg-mb-3">
          <Space style={{ textAlign: "center" }} direction="vertical">
            <span
              style={{ fontSize: 18, fontFamily: "Calibri", color: "white" }}
            >
              {titleString}
            </span>
            <SignInButton />
          </Space>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
