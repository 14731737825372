import {
  // DeleteFilled,
  // DeleteOutlined,
  // EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  UndoOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertMessageDto } from "../../../dtos/AlertMessageDto";
import { FormatDateString } from "../../../functions/utils";
import IErgColumnType from "../../../interfaces/IErgDataTableColumnType";
import { deleteAdminAlert } from "../../../queries/deleteAdminAlert";
import { GetErgTableDataAsync } from "../../../queries/ergDataTableQueries";
import { upsertAdminAlert } from "../../../queries/upsertAdminAlert";
import apiPaths from "../../../resources/api/apiPaths";
import { keys } from "../../../resources/translations/keys";
import ErgDataTable from "../../data/ErgDataTable/ErgDataTable";
import AdminAlertDetail from "../AdminAlert/AdminAlertDetail";
function AdminAlertsTable(): JSX.Element {
  const { t } = useTranslation();
  const [apiPath, setApiPath] = useState(apiPaths.getAlertMessages);
  const [selectedDetail, setSelectedDetail] = useState<AlertMessageDto>();
  const [selectedDeleteAlert, setSelectedDeleteAlert] =
    useState<AlertMessageDto>();
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [notificationapi, contextHolder] = notification.useNotification();
  const columns: IErgColumnType<AlertMessageDto>[] = [
    {
      title: t(keys.DESCRIPTION),
      dataPropertyName: "description",
      isSortable: false,
    },
    {
      title: t(keys.CREATION_DATE),
      dataPropertyName: "createdOn",
      render: (text) => <>{FormatDateString(text)}</>,
      isSortable: false,
    },
    {
      title: t(keys.EXPIRATION_DATE),
      dataPropertyName: "expiryDate",
      render: (text) => <>{FormatDateString(text)}</>,
      isSortable: false,
    },
    {
      title: t(keys.OPEN_DETAIL),
      dataPropertyName: "id",
      isSortable: false,
      render: (id, alert) => (
        <Button
          type="primary"
          icon={<ZoomInOutlined />}
          onClick={() => {
            setSelectedDetail(alert);
            setDetailModalOpen(true);
          }}
        ></Button>
      ),
    },
    {
      title: t(keys.DELETE),
      dataPropertyName: "id",
      isSortable: false,
      render: (id, alert) => (
        <Button
          icon={<DeleteOutlined />}
          type="primary"
          onClick={() => {
            setSelectedDeleteAlert(alert);
            setDeleteModalOpen(true);
          }}
        ></Button>
      ),
    },
  ];
  const handleOkDetail = () => {
    setDetailModalOpen(false);
  };

  const handleCancelDetail = () => {
    setDetailModalOpen(false);
    setSelectedDetail(undefined);
  };
  const submitForm = async (values: any) => {
    const alertDto: AlertMessageDto = {
      id: selectedDetail?.id,
      message: values.message,
      expiryDate: values.expiryDate.format("YYYY-MM-DDTHH:mm:ssZ"),
      description: values.description,
      priority: values.priority
    };
    await upsertAdminAlert(alertDto);
    setDetailModalOpen(false);
    setSelectedDetail(undefined);
    //Force api refresh
    setApiPath({ ...apiPath });
    notificationapi.info({
      message: t(keys.OPERATION_SUCCESS),
      placement: "bottom",
    });
  };
  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setSelectedDeleteAlert(undefined);
  };
  const handleOkDelete = async () => {
    if (!selectedDeleteAlert) {
      return;
    }
    await deleteAdminAlert(selectedDeleteAlert);
    setSelectedDeleteAlert(undefined);
    setDeleteModalOpen(false);
    //Force api refresh
    setApiPath({ ...apiPath });
    notificationapi.info({
      message: t(keys.OPERATION_SUCCESS),
      placement: "bottom",
    });
  };
  return (
    <>
      {contextHolder}
      <Button
        style={{ margin: 20 }}
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          setSelectedDetail(undefined);
          setDetailModalOpen(true);
        }}
      >
        {t(keys.NEW)}
      </Button>
      <div>
        <ErgDataTable<AlertMessageDto>
          apiPath={apiPath}
          apiTableQueryMethod={GetErgTableDataAsync}
          key={"id"}
          columns={columns}
        />
      </div>
      <Modal
        title={
          <div style={{ fontSize: "25px" }}>
            {t(keys.MANAGE_ALERT_MESSAGES)}
          </div>
        }
        maskStyle={{ fontSize: "50px" }}
        open={isDetailModalOpen}
        onOk={handleOkDetail}
        onCancel={handleCancelDetail}
        footer={null}
        width="70%"
      >
        <AdminAlertDetail onFinish={submitForm} alertMessage={selectedDetail} />
      </Modal>
      <Modal
        title={<div style={{ fontSize: "25px" }}>{t(keys.DELETE)}</div>}
        open={isDeleteModalOpen}
        onCancel={handleCancelDelete}
        onOk={handleOkDelete}
        centered
        footer={[
          <div style={{ textAlign: "center" }}>
            <Button
              icon={<UndoOutlined />}
              key="back"
              onClick={handleCancelDelete}
            >
              {t(keys.UNDO)}
            </Button>

            <Button
              icon={<DeleteOutlined />}
              key="submit"
              type="primary"
              onClick={handleOkDelete}
            >
              {t(keys.CONFIRM_DELETE)}
            </Button>
          </div>,
        ]}
      />
    </>
  );
}

export default AdminAlertsTable;
